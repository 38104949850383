.nav-tabs {
  border-bottom: 1px solid #e5e5e5;

  > {
    li {
      flex: 1;
      text-align: center;
      > a,
      .nav-link {
        position: relative;
        border: none;
        color: #242b3b;
        padding-top: 20px;
        font-size: 16px;
        cursor: pointer;
        font-weight: lighter;
        &:hover,
        &:focus,
        & {
          border-width: 0;
        }

        &::after {
          content: '';
          background: $primary;
          height: 3px;
          position: absolute;
          width: 100%;
          left: 0;
          bottom: -1px;
          transition: all 250ms ease 0s;
          transform: scale(0);
        }

        &.active {
          border: none;
          color: $primary !important;
          background: transparent;
          &:after {
            transform: scale(1);
          }
        }
      }

      &:hover {
        > a {
          &:after {
            transform: scale(1);
          }
        }
      }
    }
  }
}
