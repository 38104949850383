.instrucoes {
  .step-wizard-nav {
    justify-content: center;
    display: flex;
    .nav {
      // width: 75%; Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
      width: 100%;
    }
  }

  .footer {
    padding-bottom: $navbar-padding-x;
    .btn {
      &:not(.rounded-circle) {
        min-width: 113px;
        height: 35px;
        display: inline-flex;
        padding: 0;
        align-items: center;
        justify-content: center;
        &:hover {
          svg {
            fill: currentColor;
          }
        }

        .svg-icon {
          margin-right: 15px;
          margin-top: -3px;
        }

        svg {
          height: 12px;
          fill: currentColor;
          width: unset;
        }
      }
    }
  }

  .col-absolute {
    position: absolute;
    .d-flex {
      padding: 0 25px !important;
      .selecao-itens {
        background-color: #fff;
      }
    }
  }

  .row-content-instrucoes {
    .listagem-instrucoes {
      .scrollable-content {
        overflow: auto;
        height: calc(100vh - 250px);
      }
    }
    .col-data-inicio {
      padding-right: 6px;
    }
    .col-data-final {
      padding-left: 6px;
    }
    .content-instrucoes {
      transition: all 0.2s linear;
      // width: calc(100% - 270px); Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
      padding: 0 5px !important;
    }
    &.collapsed {
      .content-instrucoes {
        width: 100%;
      }
    }
    .fixed-side-info {
      @include transitions(300ms, ease);
      display: block;
      // Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
      // -ms-flex: 0 0 270px;
      // flex: 0 0 270px;
      // width: 270px;
    }
    .fixed-side {
      @include transitions(300ms, ease);
      animation: slideInRight 0.5s;
      top: 0;
      right: 0;
      max-width: 270px;
      height: 100%;
      border-left: solid 1px #e5e5e5;
      position: fixed;
      background-color: #f6f6f6;
      // padding: 0 $custom-control-spacer-x 0 $custom-control-spacer-x;
      width: 100%;
      display: flex;
      flex-direction: column;
      // overflow: hidden;

      .header {
        padding: 20px 14px 24px;
        border-bottom: 1px solid #e5e5e5;
        height: 78px;
        .title {
          color: $gray-900;
          font-family: $font-light;
          font-size: $h1-font-size;
          margin-bottom: 0;
          margin-top: 5px;
        }
      }
      .body {
        padding: 14px;
        display: flex;
        flex-direction: column;
        max-height: calc(100% - 138px);
        // min-height: 70%;
        overflow-y: auto;
        position: relative;
      }
      .bottom {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        padding: 14px;
        border-top: solid 1px #e5e5e5;
        background-color: #f6f6f6;
      }
    }
  }
  .content-instrucoes--selecao {
    overflow: auto;
    height: calc(100vh - 452px);
  }
  .content-instrucoes--dados {
    // overflow-x: hidden; Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
    overflow-x: auto;
    height: calc(100vh - 400px);
  }
  .table {
    table-layout: auto;
    .form-group {
      margin-bottom: 0;
    }
    margin-bottom: initial;
    thead {
      th {
        border-width: 1px;
        background-color: #f6f6f6;
        font-size: 12px;
        color: #4a4a4a;
        font-family: $font-semi-bold;
        white-space: nowrap;

        &.th-action {
          width: 45px;
        }
        &.th-status {
          width: 108px;
        }
      }
    }

    th,
    td {
      border-color: #e5e5e5;
      vertical-align: baseline;
    }
    .expanded-container {
      padding-left: 65px;
    }
  }
  .card {
    margin-bottom: $spacer;
    min-height: calc(100vh - 108px);
  }

  // Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
  .card-body {
    // overflow-x: hidden;
    overflow: hidden;
    padding-bottom: 0;
    transform: translateZ(0);
    // padding-top: 0.5rem;
    padding: 0.4px 0.8rem 0 1rem;
  }

  // .card-body, Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
  .card-header {
    padding-right: 0.8rem;
  }
  .title,
  .card-title {
    font-size: $h1-font-size;
    color: $gray-900;
    font-family: $font-light;
    margin-bottom: 38px;
  }
  .card-header {
    padding: 1.3rem 1.25rem 0.5rem 1.25rem;
    border-color: transparent;
  }
  .selecao-itens {
    font-size: 14px;
    .selecao-itens-numero {
      width: 40px;
      height: 28px;
      border: solid 1px #25b7f7;
      background-color: #e9f6fc;
      font-family: $font-bold;
      font-size: 15px;
      color: #25b7f7;
      text-align: center;
      line-height: 26px;
    }
  }
  .wizard {
    &.step-1 {
      .fixed-side-info {
        display: none;
      }
    }
    .fixed-side-info {
      @include transitions(300ms, ease);
      display: block;
      // Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
      // -ms-flex: 0 0 259px;
      // flex: 0 0 259px;
    }
    .body {
      h1:first-child {
        padding-top: 35px;
      }
      min-height: calc(100vh - 282px);
      legend {
        font-family: $font-semi-bold;
        margin-bottom: 14px;
        color: #242b3b;
        font-size: 12px;
      }
      .custom-label {
        font-size: $font-size-base;
        font-family: $font-semi-bold;
        color: $gray-900;
        margin-top: 1px;
        margin-bottom: 14px;
      }
      .company-name {
        margin-bottom: 40px;
        font-weight: normal;
        text-transform: none;
        font-size: 12px;
        color: #606268;
      }
      // /* The radio */
      // .radio {
      //   display: block;
      //   position: relative;
      //   padding-left: 15px;
      //   margin-bottom: 12px;
      //   cursor: pointer;
      //   font-size: 12px;
      //   user-select: none;
      // }
    }
    .footer {
      padding-bottom: $navbar-padding-x;
      .btn {
        &:not(.rounded-circle) {
          min-width: 113px;
          height: 35px;
          display: inline-flex;
          padding: 0;
          align-items: center;
          justify-content: center;
          &:hover {
            svg {
              fill: currentColor;
            }
          }

          .svg-icon {
            margin-right: 15px;
            margin-top: -3px;
          }

          svg {
            height: 12px;
            fill: currentColor;
            width: unset;
          }
        }
      }
    }
    .fixed-side {
      @include transitions(300ms, ease);
      animation: slideInRight 0.5s;
      top: 0;
      right: 0;
      max-width: 260px;
      height: 100%;
      border-left: solid 1px #e5e5e5;
      position: fixed;
      background-color: #f6f6f6;
      padding: 45px $custom-control-spacer-x 0 $custom-control-spacer-x;
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .bottom {
        margin-top: auto;
        margin-left: auto;
      }
    }
  }
  .empty-table-message {
    p,
    h4 {
      margin-bottom: 0;
    }
  }
}
.boleto-modal {
  .modal-body {
    max-height: 340px;
    overflow-y: scroll;
    .valor {
      font-family: $font-bold;
      font-size: 30px;
      margin-top: 10px;
    }
    .vencimento {
      color: $gray-500;
      font-family: $font-light;
      vertical-align: text-top;
    }
    .decricao {
      margin-top: 20px;
    }
    .boleto {
      font-family: $font-bold;
      font-size: 16px;
      margin-top: 40px;
    }
  }
}

@include media-breakpoint-down(xl) {
  .instrucoes {
    .card {
      min-height: calc(100vh - 105px);
    }
    .wizard {
      .body {
        min-height: calc(100vh - 278px);
      }
    }
  }
}

.ajusteAguarde {
  display: grid;
  position: relative;
  margin-bottom: -24px;
  z-index: 9;
}

.accordionInstrucoesPareceres {
  .card-header {
    background: #f8f8f8;
    cursor: pointer;
    font-size: 1em;
  }
}

.msgNovaInterfaceInstrucao {
  text-align: justify;
}

.esconderElemento {
  display: none;
}

// Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
// @include media-breakpoint-down(lg) {
//   .instrucoes {
//     .wizard {
//       .fixed-side-info {
//         .fixed-side {
//           right: -300px;
//         }
//         padding: 0;
//         width: 0;
//         max-width: 0;
//         overflow: hidden;
//         -ms-flex: 0;
//         flex: 0;
//       }
//     }
//   }
// }
