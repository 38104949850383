.footer-content {
  width: 100%;
  max-width: 100% !important;
  padding: 0 !important;
  .footer-powered {
    margin-top: 100%;
    text-align: center;
    width: 100%;
    height: 57px !important;
    display: flex;

    justify-content: center;
    align-items: center;
    border-top: 1px solid #e0e0e9;
    margin: 30px auto 0 auto;
  }
  .descricao {
    color: #74767e;
    font-size: 12px;
  }
}
