.wrapper {
  position: relative;
  top: 0;
  height: 100vh;

  &.wrapper-full-page {
    min-height: 100vh;
    height: auto;
  }

  // Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
  .img-logo {
    margin-right: 20px;
    width: 145px;
    height: 55px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .img-logo-collapsed {
    width: 30px;
    height: 45px;
    margin-right: 6px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.main-panel {
  min-height: 100vh;
  .content {
    @include transitions(300ms, ease);
    padding-top: $content-padding-top;
    padding-left: $sidebar-width + $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
    // width: 100vh;
  }
}

.main-without-menu {
  padding: 32px 0 100px 0;
}

.header-main {
  height: 64px;
}

.sidebar {
  // Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
  @include media-breakpoint-down(md) {
    z-index: 9999 !important;
  }
  .sidebar-burger {
    background: transparent;
    padding: 20px;
    margin-top: 10px;
    font-size: 25px !important;
    border: 0;
  }
  @include transitions(300ms, ease);
}

.btnLogoSlide {
  // background: #1a284d !important;
  // background-color: #1a284d !important;
  color: white !important;
  min-height: 69px;
}

.scrowSlide {
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 50px);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.sidebar,
.off-canvas-sidebar {
  height: 100vh;
  width: $sidebar-width;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  background-size: cover;
  background-position: center center;
  display: block;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  font-family: 'Open Sans', sans-serif;
  background-color: transparent !important;
  box-shadow: none !important;

  .sidebar-wrapper {
    width: $full-width;
    min-height: 100%;
    max-height: 100vh;
    // z-index: 4;
    position: relative;
    overflow: hidden;
    background-color: transparent !important;
    box-shadow: none !important;

    // .relatorio {
    // }

    .sidebar-toggle {
      background-color: #ffffff;
      border-bottom: solid 1px #eeeef0;
      border: 0;
      min-height: 68px;
      border-radius: 0;
      height: 68px;
      font-size: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0;
      margin: 0;
      box-shadow: none;
      width: 220px;
      min-width: 220px;
      z-index: 10;
      position: fixed;
      &:after {
        content: '\e811';
        width: 8.7px;
        height: 16px;
        margin-right: 15px; // Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
        margin-left: 10px;
        line-height: 0.5;
        color: #333333;
      }
      &:focus {
        box-shadow: none !important;
      }
    }

    .sidebar-footer {
      border-top: solid 1px #eeeef0;
      height: 80px;
      padding: 15px;
      .avatar {
        color: $white;
        background-color: $gray-500;
        height: 48px;
        width: 48px;
        min-width: 48px;
        img {
          border-radius: 50%;
        }
      }
      .company {
        transition: all 0.3s ease 0s;
        opacity: 1;
        width: 130px;
        min-width: 130px;
        p {
          margin-bottom: 0;
        }
        .welcome-text {
          color: $gray-700;
          font-size: $small-font-size;
        }
        .company-name {
          color: $gray-900;
          font-size: 12px;
        }
      }
    }
  }

  .nav {
    display: block;
    margin-top: 80px;
    border-top: 1px solid #ededf7;
    border-right: 1px solid #ededf7;
    border-bottom: 1px solid #ededf7;

    &.scrowSlide {
      background-color: #fff;
      padding-top: 10px;
      border-top-right-radius: 8px;
      height: 100vh;
    }

    li {
      > a,
      .btn {
        margin: 0;
        padding: 0 0 0 25px;
        display: flex;
        align-items: center;
        text-decoration: none;
        position: relative;
        cursor: pointer;
        border-left: 4px solid transparent;
        &.active:not(.collapse-toggle),
        &:not(.collapse-toggle):hover {
          border-left-color: $primary;
        }

        &.active {
          .sidebar-icon,
          p {
            color: $primary;
          }
        }

        &.collapse-toggle {
          p {
            display: flex;
            flex: 1;
            align-items: center;
            [class*='fa-chevron-'],
            [class^='fa-chevron-'] {
              font-size: 12px;
              margin-left: auto;
              margin-right: 12px;
              color: $gray-900;
            }
          }
          background-color: transparent;
          &:active,
          &:focus,
          & {
            border: 0;
            box-shadow: none;
          }
          width: 100%;
          padding: 0 0 0 30px;
        }

        &.btn-tutoriais {
          padding: 0 0 0 25px;

          h4 {
            i {
              width: 37px;
            }
          }
        }
      }

      .collapse {
        background-color: #f6f6f6;
        padding: 26px 0;
        transition: all 0.3s ease 0s;

        .nav-link {
          padding: 9px 20px 9px 60px;
          &.active {
            p {
              color: #242b3b;
            }
            font-family: $font-semi-bold;
          }
        }
      }
    }

    p {
      margin: 0;
      line-height: 30px;
      position: relative;
      opacity: 1;
      height: auto;
      white-space: nowrap;
      color: $gray-900;
    }

    .sidebar-icon {
      width: 33px;
      height: 51px;
      color: #b8bac6;
      display: flex;
      align-items: center;

      svg {
        width: 20px;
        height: auto;
        fill: currentColor;
      }
    }

    .sidebar-icon-documentos {
      margin-left: -2px;
      svg {
        width: 25px;
      }
    }

    .sidebar-icon-tutorial {
      margin-left: 3px;
      svg {
        width: 24px;
      }
    }
  }
}

.scrowSlide {
  width: 100%;
  @include transitions(300ms, ease);
}

@include media-breakpoint-up(xl) {
  .sidebar-mini {
    .sidebar {
      display: block;
      // z-index: 1030;
      box-shadow: $sidebar-box-shadow;
      width: $sidebar-mini-width;

      .sidebar-toggle {
        width: 220px;
        min-width: 220px;
        &:after {
          content: '\E812';
        }
      }
      .sidebar-footer {
        .company {
          opacity: 0;
        }
      }
      .sidebar-wrapper {
        width: 100% !important;

        > .nav [data-toggle='collapse'] ~ div > ul > li > a .sidebar-normal,
        .user .info [data-toggle='collapse'] ~ div > ul > li > a .sidebar-normal,
        .user .info > a > span,
        > .nav li > {
          a,
          button {
            p {
              opacity: 0;
            }
          }
        }
      }
    }

    .main-panel {
      .content {
        $sidebar-box-shadow-width: 5px;
        padding-left: $sidebar-mini-width + $sidebar-box-shadow-width + $grid-gutter-width / 2;
      }
    }
  }

  .navbar-minimize {
    button {
      margin-left: 10px;

      i {
        color: $white;
        font-size: 20px;
      }
    }
  }
}

.sidebar {
  .sidebar-burger {
    @include transitions(300ms, ease);
    display: none;
    .btn-burger {
      padding-top: $grid-gutter-width;
      padding-inline-start: $grid-gutter-width / 2;
      margin-left: $grid-gutter-width / 2;
      width: 50px;
      height: 50px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .main-panel .content {
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
  }

  .sidebar-mini {
    padding-left: 0;
    padding-right: 0;
    .sidebar {
      width: 0;
      .sidebar-burger {
        display: block;
      }
    }
  }
}

@include media-breakpoint-between(lg, xl) {
  .sidebar {
    position: fixed;
    display: block;
    top: 0;
    height: 100vh;
    right: auto;
    left: 0;
    margin: 0;
    border-radius: 0;
    // z-index: 1032;
    visibility: visible;
    overflow-y: visible;
    padding: 0;

    .sidebar-wrapper {
      height: 100vh;
    }
  }
}

@include media-breakpoint-down(md) {
  .sidebar {
    .sidebar-toggle {
      width: 200px !important;
      min-width: 200px !important;
      .img-logo {
        margin-right: 15px !important;
        width: 125px !important;
      }
    }
  }
}
