.swal-modal {
  font-family: $font-regular;
  .swal-icon {
    display: none;
  }
  .swal-title {
    margin: 0;
    padding-right: $grid-gutter-width-lg;
    padding-left: $grid-gutter-width-lg;
    font-size: 18px;
    text-align: start;
    border-bottom: solid 1px #e5e5e5;
    &:not(:first-child) {
      padding-bottom: $grid-gutter-width-lg / 2;
    }
  }
  .swal-content,
  .swal-text {
    margin: 0;
    font-size: $font-size-base;
    padding: $grid-gutter-width-lg $grid-gutter-width-lg;
    max-height: 300px;
    overflow-y: auto;
  }
  .swal-footer {
    margin: 0;
    background-color: #f6f6f6;
    padding-right: $grid-gutter-width-lg;
    padding-left: $grid-gutter-width-lg;
    .swal-button-container {
      .swal-button {
        .swal-button--confirm {
          background-color: $primary;
        }
        .swal-button--cancel {
          background-color: $primary;
        }
      }
    }
  }
}
