.modal-header {
  border-bottom: 1px solid #e5e5e5;
  .close {
    font-size: 30px;
    color: #b8bac6;
    opacity: 1;
  }
  .modal-title {
    font-family: $font-semi-bold;
    font-size: 18px;
  }
}
.modal-title,
.modal-body {
  color: #606268;
}

.modal-header,
.modal-footer,
.modal-body {
  padding-left: 30px;
  padding-right: 30px;
}
.modal-footer {
  .btn {
    min-width: 113px;
    height: 35px;
  }
  background-color: #f6f6f6;
  border-top: 0;
}

.delete-modal {
  // Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
  // width: 456px;
  // max-width: none;
  max-width: 450px;

  .modal-body {
    padding-top: 36px;
    font-size: 14px;
    min-height: 120px;
  }
}

.modalInclusaoTitulo {
  // Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
  // width: 676px;
  // max-width: none;
  max-width: 676px;
  .modal-body {
    padding: 0;
  }
  .tab-pane {
    padding: 42px 30px 30px;
  }
  .tab-content {
    padding: 0;
  }

  .title {
    font-family: $font-semi-bold;
    font-size: 16px;
  }
}
