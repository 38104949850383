.retorno {
  .card {
    .card-body {
      overflow-x: hidden;
      padding-bottom: 0;
      transform: translateZ(0);
      height: calc(100vh - 106px);

      .retorno-content {
        .title {
          margin-top: 5px;
        }
        .btn.btn-retorno {
          width: 100%;
          height: 48px;
          border-radius: 4px;
          color: #606268;
          border: solid 1px #e5e5e5;
          background-color: #ffffff;

          &:hover {
            border-color: $primary;
            color: $primary;
          }

          &:focus {
            box-shadow: none;
          }
        }
      }

      .title {
        color: $gray-900;
        font-family: $font-light;
        font-size: $h1-font-size;
        margin-bottom: 0;
        margin-top: 5px;
      }
      .subTitle {
        font-size: 12px;
        margin-top: 12px;
      }

      .content-retorno {
        overflow-y: auto;
        height: calc(100vh - 225px);
        margin-right: -8px;
        .row {
          margin-right: 0px;
        }
      }

      .fixed-side-info {
        @include transitions(300ms, ease);
        display: block;
        // Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
        // -ms-flex: 0 0 269px;
        // flex: 0 0 269px;
        // width: 269px;
      }
      .fixed-side {
        @include transitions(300ms, ease);
        animation: slideInRight 0.5s;
        top: 0;
        right: 0;
        // max-width: 260px; Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
        height: 100%;
        border-left: solid 1px #e5e5e5;
        position: fixed;
        background-color: #f6f6f6;
        // padding: 0 $custom-control-spacer-x 0 $custom-control-spacer-x;
        width: 100%;
        display: flex;
        flex-direction: column;
        // overflow: hidden;

        .header {
          padding: 20px 14px 24px;
          border-bottom: 1px solid #e5e5e5;
        }

        .body {
          padding: 14px;
          display: flex;
          flex-direction: column;
          max-height: calc(100% - 150px);
          // min-height: 70%;
          // overflow-y: auto;
          position: relative;
        }
        .bottom {
          position: absolute;
          bottom: 0;
          width: 100%;
          left: 0;
          padding: 14px;
          border-top: solid 1px #e5e5e5;
          background-color: #f6f6f6;
        }
      }
    }
  }
}
.flex-1 {
  flex: 1;
}
