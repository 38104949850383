@mixin transform-translate-x($value) {
  -webkit-transform: translate3d($value, 0, 0);
  -moz-transform: translate3d($value, 0, 0);
  -o-transform: translate3d($value, 0, 0);
  -ms-transform: translate3d($value, 0, 0);
  transform: translate3d($value, 0, 0);
}

@mixin transform-translate-y($value) {
  -webkit-transform: translate3d(0, $value, 0);
  -moz-transform: translate3d(0, $value, 0);
  -o-transform: translate3d(0, $value, 0);
  -ms-transform: translate3d(0, $value, 0);
  transform: translate3d(0, $value, 0);
}

@mixin transitions($time, $type) {
  -webkit-transition: all $time $type;
  -moz-transition: all $time $type;
  -o-transition: all $time $type;
  -ms-transition: all $time $type;
  transition: all $time $type;
}

@mixin diagonal-gradient($start-color, $end-color) {
  background: $start-color;
  background-image: -webkit-linear-gradient(to bottom left, $start-color, $end-color, $start-color);
  background-image: -o-linear-gradient(to bottom left, $start-color, $end-color, $start-color);
  background-image: -moz-linear-gradient(to bottom left, $start-color, $end-color, $start-color);
  background-image: linear-gradient(to bottom left, $start-color, $end-color, $start-color);
  background-size: 210% 210%;
  background-position: top right;
}

@mixin rotate-180() {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

@mixin linear-gradient($color, $states-color) {
  background: $color;
  background: -webkit-linear-gradient(0deg, $color 0%, $states-color 100%);
  background: -o-linear-gradient(0deg, $color 0%, $states-color 100%);
  background: -moz-linear-gradient(0deg, $color 0%, $states-color 100%);
  background: linear-gradient(0deg, $color 0%, $states-color 100%);
}

@mixin linear-gradient-right($color, $states-color) {
  background: $color;
  background: -webkit-linear-gradient(to right, $color 0%, $states-color 100%);
  background: -o-linear-gradient(to right, $color 0%, $states-color 100%);
  background: -moz-linear-gradient(to right, $color 0%, $states-color 100%);
  background: linear-gradient(to right, $color 0%, $states-color 100%);
}

@mixin sidebar-color($color) {
  &:after {
    background: $color;
  }

  .nav li.active > a {
    color: $color;

    i {
      color: $color;
    }
  }
}

@mixin transition-input-focus-color() {
  -webkit-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  -ms-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
