.step-wizard-nav {
  .nav {
    padding-top: 35px;
    .step {
      &:not(:last-of-type) {
        &:after {
          top: 10px;
          bottom: 0;
          position: absolute;
          content: ' ';
          width: 100%;
          height: 1px;
          background-color: #979797;
          z-index: 0;
          margin-left: auto;
          margin-right: auto;
          left: 52%; // 6.30vw;
          // clip-path: circle(15vw at left);
        }
      }
      &.active {
        &:not(.completed) {
          .dot {
            background-color: #daeff7;
          }
          .inner-dot {
            background-color: $primary;
          }
        }

        .step-name {
          font-family: $font-semi-bold;
          color: $primary;
        }
      }
      &.completed {
        &:not(:last-of-type) {
          &:after {
            background-color: $success;
          }
        }

        .dot {
          background-color: #e7f2e3;
        }
        .inner-dot {
          background-color: $success;
        }
        .step-name {
          color: $success;
        }
      }
    }
    .dot {
      width: 21px;
      height: 21px;
      background-color: #e5e5e5;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-left: -1px;
      padding: 0;
      border: none;

      &:active,
      &:focus,
      &:hover {
        box-shadow: none;
      }

      &:disabled {
        opacity: 1;
      }

      .inner-dot {
        width: 11px;
        height: 11px;
        background-color: #b8bac6;
      }

      .inner-dot,
      & {
        border-radius: 50%;
      }
    }
    .step-name {
      margin-top: 10px;
      font-size: 12px;
      color: #606268;
    }
  }
}
