/* all icon font classes list here */
@font-face {
  font-family: 'fa';
  src: url('./assets/fonts/fa.eot?41733459');
  src: url('./assets/fonts/fa.eot?41733459#iefix') format('embedded-opentype'),
    url('./assets/fonts/fa.woff2?41733459') format('woff2'),
    url('./assets/fonts/fa.woff?41733459') format('woff'),
    url('./assets/fonts/fa.ttf?41733459') format('truetype'),
    url('./assets/fonts/fa.svg?41733459#fa') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'fa';
    src: url('./assets/fonts/fa.svg?41733459#fa') format('svg');
  }
}

[class^='fa-']:before,
[class*=' fa-']:before,
.sidebar-toggle:after {
  font-family: 'fa';
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1.28571429em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

/* all icon font classes list here */
.fa-address-book:before {
  content: '\e800';
}

/* '' */
.fa-address-card:before {
  content: '\e801';
}

/* '' */
.fa-adjust:before {
  content: '\e802';
}

/* '' */
.fa-alarm-clock:before {
  content: '\e803';
}

/* '' */
.fa-align-justify:before {
  content: '\e804';
}

/* '' */
.fa-align-center:before {
  content: '\e805';
}

/* '' */
.fa-align-left:before {
  content: '\e806';
}

/* '' */
.fa-align-right:before {
  content: '\e807';
}

/* '' */
.fa-allergies:before {
  content: '\e808';
}

/* '' */
.fa-ambulance:before {
  content: '\e809';
}

/* '' */
.fa-american-sign-language-interpreting:before {
  content: '\e80a';
}

/* '' */
.fa-anchor:before {
  content: '\e80b';
}

/* '' */
.fa-angle-double-down:before {
  content: '\e80c';
}

/* '' */
.fa-angle-double-left:before {
  content: '\e80d';
}

/* '' */
.fa-angle-double-right:before {
  content: '\e80e';
}

/* '' */
.fa-angle-double-up:before {
  content: '\e80f';
}

/* '' */
.fa-angle-down:before {
  content: '\e810';
}

/* '' */
.fa-angle-left:before {
  content: '\e811';
}

/* '' */
.fa-angle-right:before {
  content: '\e812';
}

/* '' */
.fa-angle-up:before {
  content: '\e813';
}

/* '' */
.fa-angry:before {
  content: '\e814';
}

/* '' */
.fa-archive:before {
  content: '\e815';
}

/* '' */
.fa-archway:before {
  content: '\e816';
}

/* '' */
.fa-arrow-alt-circle-down:before {
  content: '\e817';
}

/* '' */
.fa-arrow-alt-circle-left:before {
  content: '\e818';
}

/* '' */
.fa-arrow-alt-circle-right:before {
  content: '\e819';
}

/* '' */
.fa-arrow-alt-circle-up:before {
  content: '\e81a';
}

/* '' */
.fa-arrow-alt-down:before {
  content: '\e81b';
}

/* '' */
.fa-arrow-alt-from-bottom:before {
  content: '\e81c';
}

/* '' */
.fa-arrow-alt-from-left:before {
  content: '\e81d';
}

/* '' */
.fa-arrow-alt-from-right:before {
  content: '\e81e';
}

/* '' */
.fa-arrow-alt-from-top:before {
  content: '\e81f';
}

/* '' */
.fa-arrow-alt-left:before {
  content: '\e820';
}

/* '' */
.fa-arrow-alt-right:before {
  content: '\e821';
}

/* '' */
.fa-arrow-alt-square-down:before {
  content: '\e822';
}

/* '' */
.fa-arrow-alt-square-left:before {
  content: '\e823';
}

/* '' */
.fa-arrow-alt-square-right:before {
  content: '\e824';
}

/* '' */
.fa-arrow-alt-square-up:before {
  content: '\e825';
}

/* '' */
.fa-arrow-alt-to-bottom:before {
  content: '\e826';
}

/* '' */
.fa-arrow-alt-to-left:before {
  content: '\e827';
}

/* '' */
.fa-arrow-alt-to-right:before {
  content: '\e828';
}

/* '' */
.fa-arrow-alt-to-top:before {
  content: '\e829';
}

/* '' */
.fa-arrow-alt-up:before {
  content: '\e82a';
}

/* '' */
.fa-arrow-circle-down:before {
  content: '\e82b';
}

/* '' */
.fa-arrow-circle-left:before {
  content: '\e82c';
}

/* '' */
.fa-arrow-circle-right:before {
  content: '\e82d';
}

/* '' */
.fa-arrow-circle-up:before {
  content: '\e82e';
}

/* '' */
.fa-arrow-down:before {
  content: '\e82f';
}

/* '' */
.fa-arrow-from-bottom:before {
  content: '\e830';
}

/* '' */
.fa-arrow-from-left:before {
  content: '\e831';
}

/* '' */
.fa-arrow-from-right:before {
  content: '\e832';
}

/* '' */
.fa-arrow-from-top:before {
  content: '\e833';
}

/* '' */
.fa-arrow-left:before {
  content: '\e834';
}

/* '' */
.fa-arrow-right:before {
  content: '\e835';
}

/* '' */
.fa-arrow-square-down:before {
  content: '\e836';
}

/* '' */
.fa-arrow-square-left:before {
  content: '\e837';
}

/* '' */
.fa-arrow-square-right:before {
  content: '\e838';
}

/* '' */
.fa-arrow-square-up:before {
  content: '\e839';
}

/* '' */
.fa-arrow-to-bottom:before {
  content: '\e83a';
}

/* '' */
.fa-arrow-to-left:before {
  content: '\e83b';
}

/* '' */
.fa-arrow-to-right:before {
  content: '\e83c';
}

/* '' */
.fa-arrow-to-top:before {
  content: '\e83d';
}

/* '' */
.fa-arrow-up:before {
  content: '\e83e';
}

/* '' */
.fa-arrows-alt-h:before {
  content: '\e83f';
}

/* '' */
.fa-arrows-alt-v:before {
  content: '\e840';
}

/* '' */
.fa-arrows-alt:before {
  content: '\e841';
}

/* '' */
.fa-arrows-h:before {
  content: '\e842';
}

/* '' */
.fa-arrows-v:before {
  content: '\e843';
}

/* '' */
.fa-arrows:before {
  content: '\e844';
}

/* '' */
.fa-assistive-listening-systems:before {
  content: '\e845';
}

/* '' */
.fa-asterisk:before {
  content: '\e846';
}

/* '' */
.fa-at:before {
  content: '\e847';
}

/* '' */
.fa-atlas:before {
  content: '\e848';
}

/* '' */
.fa-award:before {
  content: '\e849';
}

/* '' */
.fa-audio-description:before {
  content: '\e84a';
}

/* '' */
.fa-backspace:before {
  content: '\e84b';
}

/* '' */
.fa-backward:before {
  content: '\e84c';
}

/* '' */
.fa-badge-check:before {
  content: '\e84d';
}

/* '' */
.fa-badge:before {
  content: '\e84e';
}

/* '' */
.fa-balance-scale-left:before {
  content: '\e84f';
}

/* '' */
.fa-balance-scale-right:before {
  content: '\e850';
}

/* '' */
.fa-balance-scale:before {
  content: '\e851';
}

/* '' */
.fa-ban:before {
  content: '\e852';
}

/* '' */
.fa-band-aid:before {
  content: '\e853';
}

/* '' */
.fa-barcode-alt:before {
  content: '\e854';
}

/* '' */
.fa-barcode-read:before {
  content: '\e855';
}

/* '' */
.fa-barcode-scan:before {
  content: '\e856';
}

/* '' */
.fa-barcode:before {
  content: '\e857';
}

/* '' */
.fa-bars:before {
  content: '\e858';
}

/* '' */
.fa-baseball-ball:before {
  content: '\e859';
}

/* '' */
.fa-baseball:before {
  content: '\e85a';
}

/* '' */
.fa-basketball-ball:before {
  content: '\e85b';
}

/* '' */
.fa-basketball-hoop:before {
  content: '\e85c';
}

/* '' */
.fa-bath:before {
  content: '\e85d';
}

/* '' */
.fa-battery-bolt:before {
  content: '\e85e';
}

/* '' */
.fa-battery-empty:before {
  content: '\e85f';
}

/* '' */
.fa-battery-full:before {
  content: '\e860';
}

/* '' */
.fa-battery-half:before {
  content: '\e861';
}

/* '' */
.fa-battery-quarter:before {
  content: '\e862';
}

/* '' */
.fa-battery-slash:before {
  content: '\e863';
}

/* '' */
.fa-battery-three-quarters:before {
  content: '\e864';
}

/* '' */
.fa-bed:before {
  content: '\e865';
}

/* '' */
.fa-beer:before {
  content: '\e866';
}

/* '' */
.fa-bell-slash:before {
  content: '\e867';
}

/* '' */
.fa-bell:before {
  content: '\e868';
}

/* '' */
.fa-bezier-curve:before {
  content: '\e869';
}

/* '' */
.fa-bicycle:before {
  content: '\e86a';
}

/* '' */
.fa-binoculars:before {
  content: '\e86b';
}

/* '' */
.fa-birthday-cake:before {
  content: '\e86c';
}

/* '' */
.fa-blanket:before {
  content: '\e86d';
}

/* '' */
.fa-blender:before {
  content: '\e86e';
}

/* '' */
.fa-blind:before {
  content: '\e86f';
}

/* '' */
.fa-bold:before {
  content: '\e870';
}

/* '' */
.fa-bolt:before {
  content: '\e871';
}

/* '' */
.fa-bomb:before {
  content: '\e872';
}

/* '' */
.fa-bong:before {
  content: '\e873';
}

/* '' */
.fa-book-heart:before {
  content: '\e874';
}

/* '' */
.fa-book-open:before {
  content: '\e875';
}

/* '' */
.fa-book:before {
  content: '\e876';
}

/* '' */
.fa-bookmark:before {
  content: '\e877';
}

/* '' */
.fa-bowling-ball:before {
  content: '\e878';
}

/* '' */
.fa-bowling-pins:before {
  content: '\e879';
}

/* '' */
.fa-box-alt:before {
  content: '\e87a';
}

/* '' */
.fa-box-check:before {
  content: '\e87b';
}

/* '' */
.fa-box-fragile:before {
  content: '\e87c';
}

/* '' */
.fa-box-full:before {
  content: '\e87d';
}

/* '' */
.fa-box-heart:before {
  content: '\e87e';
}

/* '' */
.fa-box-open:before {
  content: '\e87f';
}

/* '' */
.fa-box-up:before {
  content: '\e880';
}

/* '' */
.fa-box-usd:before {
  content: '\e881';
}

/* '' */
.fa-box:before {
  content: '\e882';
}

/* '' */
.fa-boxes-alt:before {
  content: '\e883';
}

/* '' */
.fa-boxes:before {
  content: '\e884';
}

/* '' */
.fa-boxing-glove:before {
  content: '\e885';
}

/* '' */
.fa-braille:before {
  content: '\e886';
}

/* '' */
.fa-briefcase-medical:before {
  content: '\e887';
}

/* '' */
.fa-briefcase:before {
  content: '\e888';
}

/* '' */
.fa-broadcast-tower:before {
  content: '\e889';
}

/* '' */
.fa-broom:before {
  content: '\e88a';
}

/* '' */
.fa-browser:before {
  content: '\e88b';
}

/* '' */
.fa-brush:before {
  content: '\e88c';
}

/* '' */
.fa-bug:before {
  content: '\e88d';
}

/* '' */
.fa-building:before {
  content: '\e88e';
}

/* '' */
.fa-bullhorn:before {
  content: '\e88f';
}

/* '' */
.fa-bullseye:before {
  content: '\e890';
}

/* '' */
.fa-burn:before {
  content: '\e891';
}

/* '' */
.fa-bus-alt:before {
  content: '\e892';
}

/* '' */
.fa-bus:before {
  content: '\e893';
}

/* '' */
.fa-calculator:before {
  content: '\e894';
}

/* '' */
.fa-calendar-alt:before {
  content: '\e895';
}

/* '' */
.fa-calendar-check:before {
  content: '\e896';
}

/* '' */
.fa-calendar-edit:before {
  content: '\e897';
}

/* '' */
.fa-calendar-exclamation:before {
  content: '\e898';
}

/* '' */
.fa-calendar-minus:before {
  content: '\e899';
}

/* '' */
.fa-calendar-plus:before {
  content: '\e89a';
}

/* '' */
.fa-calendar-times:before {
  content: '\e89b';
}

/* '' */
.fa-calendar:before {
  content: '\e89c';
}

/* '' */
.fa-camera-alt:before {
  content: '\e89d';
}

/* '' */
.fa-camera-retro:before {
  content: '\e89e';
}

/* '' */
.fa-camera:before {
  content: '\e89f';
}

/* '' */
.fa-cannabis:before {
  content: '\e8a0';
}

/* '' */
.fa-capsules:before {
  content: '\e8a1';
}

/* '' */
.fa-car:before {
  content: '\e8a2';
}

/* '' */
.fa-caret-circle-down:before {
  content: '\e8a3';
}

/* '' */
.fa-caret-circle-left:before {
  content: '\e8a4';
}

/* '' */
.fa-caret-circle-right:before {
  content: '\e8a5';
}

/* '' */
.fa-caret-circle-up:before {
  content: '\e8a6';
}

/* '' */
.fa-caret-down:before {
  content: '\e8a7';
}

/* '' */
.fa-caret-left:before {
  content: '\e8a8';
}

/* '' */
.fa-caret-right:before {
  content: '\e8a9';
}

/* '' */
.fa-caret-square-down:before {
  content: '\e8aa';
}

/* '' */
.fa-caret-square-left:before {
  content: '\e8ab';
}

/* '' */
.fa-caret-square-right:before {
  content: '\e8ac';
}

/* '' */
.fa-caret-square-up:before {
  content: '\e8ad';
}

/* '' */
.fa-caret-up:before {
  content: '\e8ae';
}

/* '' */
.fa-cart-arrow-down:before {
  content: '\e8af';
}

/* '' */
.fa-cart-plus:before {
  content: '\e8b0';
}

/* '' */
.fa-certificate:before {
  content: '\e8b1';
}

/* '' */
.fa-chalkboard-teacher:before {
  content: '\e8b2';
}

/* '' */
.fa-chalkboard:before {
  content: '\e8b3';
}

/* '' */
.fa-chart-area:before {
  content: '\e8b4';
}

/* '' */
.fa-chart-bar:before {
  content: '\e8b5';
}

/* '' */
.fa-chart-line:before {
  content: '\e8b6';
}

/* '' */
.fa-chart-pie:before {
  content: '\e8b7';
}

/* '' */
.fa-check-circle:before {
  content: '\e8b8';
}

/* '' */
.fa-check-double:before {
  content: '\e8b9';
}

/* '' */
.fa-check-square:before {
  content: '\e8ba';
}

/* '' */
.fa-check:before {
  content: '\e8bb';
}

/* '' */
.fa-chess-bishop-alt:before {
  content: '\e8bc';
}

/* '' */
.fa-chess-bishop:before {
  content: '\e8bd';
}

/* '' */
.fa-chess-board:before {
  content: '\e8be';
}

/* '' */
.fa-chess-clock-alt:before {
  content: '\e8bf';
}

/* '' */
.fa-chess-clock:before {
  content: '\e8c0';
}

/* '' */
.fa-chess-king-alt:before {
  content: '\e8c1';
}

/* '' */
.fa-chess-king:before {
  content: '\e8c2';
}

/* '' */
.fa-chess-knight-alt:before {
  content: '\e8c3';
}

/* '' */
.fa-chess-knight:before {
  content: '\e8c4';
}

/* '' */
.fa-chess-pawn-alt:before {
  content: '\e8c5';
}

/* '' */
.fa-chess-pawn:before {
  content: '\e8c6';
}

/* '' */
.fa-chess-queen-alt:before {
  content: '\e8c7';
}

/* '' */
.fa-chess-queen:before {
  content: '\e8c8';
}

/* '' */
.fa-chess-rook-alt:before {
  content: '\e8c9';
}

/* '' */
.fa-chess-rook:before {
  content: '\e8ca';
}

/* '' */
.fa-chess:before {
  content: '\e8cb';
}

/* '' */
.fa-chevron-circle-down:before {
  content: '\e8cc';
}

/* '' */
.fa-chevron-circle-left:before {
  content: '\e8cd';
}

/* '' */
.fa-chevron-circle-right:before {
  content: '\e8ce';
}

/* '' */
.fa-chevron-circle-up:before {
  content: '\e8cf';
}

/* '' */
.fa-chevron-double-down:before {
  content: '\e8d0';
}

/* '' */
.fa-chevron-double-left:before {
  content: '\e8d1';
}

/* '' */
.fa-chevron-double-right:before {
  content: '\e8d2';
}

/* '' */
.fa-chevron-double-up:before {
  content: '\e8d3';
}

/* '' */
.fa-chevron-down:before {
  content: '\e8d4';
}

/* '' */
.fa-chevron-left:before {
  content: '\e8d5';
}

/* '' */
.fa-chevron-right:before {
  content: '\e8d6';
}

/* '' */
.fa-chevron-square-down:before {
  content: '\e8d7';
}

/* '' */
.fa-chevron-square-left:before {
  content: '\e8d8';
}

/* '' */
.fa-chevron-square-right:before {
  content: '\e8d9';
}

/* '' */
.fa-chevron-square-up:before {
  content: '\e8da';
}

/* '' */
.fa-chevron-up:before {
  content: '\e8db';
}

/* '' */
.fa-child:before {
  content: '\e8dc';
}

/* '' */
.fa-church:before {
  content: '\e8dd';
}

/* '' */
.fa-circle-notch:before {
  content: '\e8de';
}

/* '' */
.fa-circle:before {
  content: '\e8df';
}

/* '' */
.fa-clipboard-check:before {
  content: '\e8e0';
}

/* '' */
.fa-clipboard-list:before {
  content: '\e8e1';
}

/* '' */
.fa-clipboard:before {
  content: '\e8e2';
}

/* '' */
.fa-clock:before {
  content: '\e8e3';
}

/* '' */
.fa-clone:before {
  content: '\e8e4';
}

/* '' */
.fa-closed-captioning:before {
  content: '\e8e5';
}

/* '' */
.fa-cloud-download-alt:before {
  content: '\e8e6';
}

/* '' */
.fa-cloud-download:before {
  content: '\e8e7';
}

/* '' */
.fa-cloud-upload-alt:before {
  content: '\e8e8';
}

/* '' */
.fa-cloud-upload:before {
  content: '\e8e9';
}

/* '' */
.fa-cloud:before {
  content: '\e8ea';
}

/* '' */
.fa-club:before {
  content: '\e8eb';
}

/* '' */
.fa-cocktail:before {
  content: '\e8ec';
}

/* '' */
.fa-code-branch:before {
  content: '\e8ed';
}

/* '' */
.fa-code-commit:before {
  content: '\e8ee';
}

/* '' */
.fa-code-merge:before {
  content: '\e8ef';
}

/* '' */
.fa-code:before {
  content: '\e8f0';
}

/* '' */
.fa-coffee:before {
  content: '\e8f1';
}

/* '' */
.fa-cog:before {
  content: '\e8f2';
}

/* '' */
.fa-cogs:before {
  content: '\e8f3';
}

/* '' */
.fa-coins:before {
  content: '\e8f4';
}

/* '' */
.fa-columns:before {
  content: '\e8f5';
}

/* '' */
.fa-comment-alt-check:before {
  content: '\e8f6';
}

/* '' */
.fa-comment-alt-dots:before {
  content: '\e8f7';
}

/* '' */
.fa-comment-alt-edit:before {
  content: '\e8f8';
}

/* '' */
.fa-comment-alt-exclamation:before {
  content: '\e8f9';
}

/* '' */
.fa-comment-alt-lines:before {
  content: '\e8fa';
}

/* '' */
.fa-comment-alt-minus:before {
  content: '\e8fb';
}

/* '' */
.fa-comment-alt-plus:before {
  content: '\e8fc';
}

/* '' */
.fa-comment-alt-slash:before {
  content: '\e8fd';
}

/* '' */
.fa-comment-alt-smile:before {
  content: '\e8fe';
}

/* '' */
.fa-comment-alt-times:before {
  content: '\e8ff';
}

/* '' */
.fa-comment-alt:before {
  content: '\e900';
}

/* '' */
.fa-comment-check:before {
  content: '\e901';
}

/* '' */
.fa-comment-dots:before {
  content: '\e902';
}

/* '' */
.fa-comment-edit:before {
  content: '\e903';
}

/* '' */
.fa-comment-exclamation:before {
  content: '\e904';
}

/* '' */
.fa-comment-lines:before {
  content: '\e905';
}

/* '' */
.fa-comment-minus:before {
  content: '\e906';
}

/* '' */
.fa-comment-plus:before {
  content: '\e907';
}

/* '' */
.fa-comment-slash:before {
  content: '\e908';
}

/* '' */
.fa-comment-smile:before {
  content: '\e909';
}

/* '' */
.fa-comment-times:before {
  content: '\e90a';
}

/* '' */
.fa-comment:before {
  content: '\e90b';
}

/* '' */
.fa-comments-alt:before {
  content: '\e90c';
}

/* '' */
.fa-comments:before {
  content: '\e90d';
}

/* '' */
.fa-compact-disc:before {
  content: '\e90e';
}

/* '' */
.fa-compass:before {
  content: '\e90f';
}

/* '' */
.fa-compress-alt:before {
  content: '\e910';
}

/* '' */
.fa-compress-wide:before {
  content: '\e911';
}

/* '' */
.fa-compress:before {
  content: '\e912';
}

/* '' */
.fa-concierge-bell:before {
  content: '\e913';
}

/* '' */
.fa-container-storage:before {
  content: '\e914';
}

/* '' */
.fa-conveyor-belt-alt:before {
  content: '\e915';
}

/* '' */
.fa-conveyor-belt:before {
  content: '\e916';
}

/* '' */
.fa-cookie-bite:before {
  content: '\e917';
}

/* '' */
.fa-cookie:before {
  content: '\e918';
}

/* '' */
.fa-copy:before {
  content: '\e919';
}

/* '' */
.fa-copyright:before {
  content: '\e91a';
}

/* '' */
.fa-couch:before {
  content: '\e91b';
}

/* '' */
.fa-credit-card-blank:before {
  content: '\e91c';
}

/* '' */
.fa-credit-card-front:before {
  content: '\e91d';
}

/* '' */
.fa-credit-card:before {
  content: '\e91e';
}

/* '' */
.fa-cricket:before {
  content: '\e91f';
}

/* '' */
.fa-crop-alt:before {
  content: '\e920';
}

/* '' */
.fa-crop:before {
  content: '\e921';
}

/* '' */
.fa-crosshairs:before {
  content: '\e922';
}

/* '' */
.fa-crow:before {
  content: '\e923';
}

/* '' */
.fa-crown:before {
  content: '\e924';
}

/* '' */
.fa-cube:before {
  content: '\e925';
}

/* '' */
.fa-cubes:before {
  content: '\e926';
}

/* '' */
.fa-curling:before {
  content: '\e927';
}

/* '' */
.fa-cut:before {
  content: '\e928';
}

/* '' */
.fa-database:before {
  content: '\e929';
}

/* '' */
.fa-deaf:before {
  content: '\e92a';
}

/* '' */
.fa-desktop-alt:before {
  content: '\e92b';
}

/* '' */
.fa-desktop:before {
  content: '\e92c';
}

/* '' */
.fa-diagnoses:before {
  content: '\e92d';
}

/* '' */
.fa-diamond:before {
  content: '\e92e';
}

/* '' */
.fa-dice-five:before {
  content: '\e92f';
}

/* '' */
.fa-dice-four:before {
  content: '\e930';
}

/* '' */
.fa-dice-one:before {
  content: '\e931';
}

/* '' */
.fa-dice-six:before {
  content: '\e932';
}

/* '' */
.fa-dice-three:before {
  content: '\e933';
}

/* '' */
.fa-dice-two:before {
  content: '\e934';
}

/* '' */
.fa-dice:before {
  content: '\e935';
}

/* '' */
.fa-digital-tachograph:before {
  content: '\e936';
}

/* '' */
.fa-divide:before {
  content: '\e937';
}

/* '' */
.fa-dizzy:before {
  content: '\e938';
}

/* '' */
.fa-dna:before {
  content: '\e939';
}

/* '' */
.fa-dollar-sign:before {
  content: '\e93a';
}

/* '' */
.fa-dolly-empty:before {
  content: '\e93b';
}

/* '' */
.fa-dolly-flatbed-alt:before {
  content: '\e93c';
}

/* '' */
.fa-dolly-flatbed-empty:before {
  content: '\e93d';
}

/* '' */
.fa-dolly-flatbed:before {
  content: '\e93e';
}

/* '' */
.fa-dolly:before {
  content: '\e93f';
}

/* '' */
.fa-donate:before {
  content: '\e940';
}

/* '' */
.fa-door-closed:before {
  content: '\e941';
}

/* '' */
.fa-door-open:before {
  content: '\e942';
}

/* '' */
.fa-dot-circle:before {
  content: '\e943';
}

/* '' */
.fa-dove:before {
  content: '\e944';
}

/* '' */
.fa-download:before {
  content: '\e945';
}

/* '' */
.fa-drafting-compass:before {
  content: '\e946';
}

/* '' */
.fa-drum-steelpan:before {
  content: '\e947';
}

/* '' */
.fa-drum:before {
  content: '\e948';
}

/* '' */
.fa-dumbbell:before {
  content: '\e949';
}

/* '' */
.fa-edit:before {
  content: '\e94a';
}

/* '' */
.fa-eject:before {
  content: '\e94b';
}

/* '' */
.fa-ellipsis-h-alt:before {
  content: '\e94c';
}

/* '' */
.fa-ellipsis-h:before {
  content: '\e94d';
}

/* '' */
.fa-ellipsis-v-alt:before {
  content: '\e94e';
}

/* '' */
.fa-ellipsis-v:before {
  content: '\e94f';
}

/* '' */
.fa-envelope-open:before {
  content: '\e950';
}

/* '' */
.fa-envelope-square:before {
  content: '\e951';
}

/* '' */
.fa-envelope:before {
  content: '\e952';
}

/* '' */
.fa-equals:before {
  content: '\e953';
}

/* '' */
.fa-eraser:before {
  content: '\e954';
}

/* '' */
.fa-euro-sign:before {
  content: '\e955';
}

/* '' */
.fa-exchange-alt:before {
  content: '\e956';
}

/* '' */
.fa-exchange:before {
  content: '\e957';
}

/* '' */
.fa-exclamation-circle:before {
  content: '\e958';
}

/* '' */
.fa-exclamation-square:before {
  content: '\e959';
}

/* '' */
.fa-exclamation-triangle:before {
  content: '\e95a';
}

/* '' */
.fa-exclamation:before {
  content: '\e95b';
}

/* '' */
.fa-expand-alt:before {
  content: '\e95c';
}

/* '' */
.fa-expand-arrows-alt:before {
  content: '\e95d';
}

/* '' */
.fa-expand-arrows:before {
  content: '\e95e';
}

/* '' */
.fa-expand-wide:before {
  content: '\e95f';
}

/* '' */
.fa-expand:before {
  content: '\e960';
}

/* '' */
.fa-external-link-alt:before {
  content: '\e961';
}

/* '' */
.fa-external-link-square-alt:before {
  content: '\e962';
}

/* '' */
.fa-external-link-square:before {
  content: '\e963';
}

/* '' */
.fa-external-link:before {
  content: '\e964';
}

/* '' */
.fa-eye-dropper:before {
  content: '\e965';
}

/* '' */
.fa-eye-slash:before {
  content: '\e966';
}

/* '' */
.fa-eye:before {
  content: '\e967';
}

/* '' */
.fa-fast-backward:before {
  content: '\e968';
}

/* '' */
.fa-fast-forward:before {
  content: '\e969';
}

/* '' */
.fa-fax:before {
  content: '\e96a';
}

/* '' */
.fa-feather-alt:before {
  content: '\e96b';
}

/* '' */
.fa-feather:before {
  content: '\e96c';
}

/* '' */
.fa-female:before {
  content: '\e96d';
}

/* '' */
.fa-field-hockey:before {
  content: '\e96e';
}

/* '' */
.fa-fighter-jet:before {
  content: '\e96f';
}

/* '' */
.fa-file-alt:before {
  content: '\e970';
}

/* '' */
.fa-file-archive:before {
  content: '\e971';
}

/* '' */
.fa-file-audio:before {
  content: '\e972';
}

/* '' */
.fa-file-check:before {
  content: '\e973';
}

/* '' */
.fa-file-code:before {
  content: '\e974';
}

/* '' */
.fa-file-contract:before {
  content: '\e975';
}

/* '' */
.fa-file-download:before {
  content: '\e976';
}

/* '' */
.fa-file-edit:before {
  content: '\e977';
}

/* '' */
.fa-file-excel:before {
  content: '\e978';
}

/* '' */
.fa-file-exclamation:before {
  content: '\e979';
}

/* '' */
.fa-file-export:before {
  content: '\e97a';
}

/* '' */
.fa-file-image:before {
  content: '\e97b';
}

/* '' */
.fa-file-import:before {
  content: '\e97c';
}

/* '' */
.fa-file-invoice-dollar:before {
  content: '\e97d';
}

/* '' */
.fa-file-invoice:before {
  content: '\e97e';
}

/* '' */
.fa-file-medical-alt:before {
  content: '\e97f';
}

/* '' */
.fa-file-medical:before {
  content: '\e980';
}

/* '' */
.fa-file-minus:before {
  content: '\e981';
}

/* '' */
.fa-file-pdf:before {
  content: '\e982';
}

/* '' */
.fa-file-plus:before {
  content: '\e983';
}

/* '' */
.fa-file-powerpoint:before {
  content: '\e984';
}

/* '' */
.fa-file-prescription:before {
  content: '\e985';
}

/* '' */
.fa-file-signature:before {
  content: '\e986';
}

/* '' */
.fa-file-times:before {
  content: '\e987';
}

/* '' */
.fa-file-upload:before {
  content: '\e988';
}

/* '' */
.fa-file-video:before {
  content: '\e989';
}

/* '' */
.fa-file-word:before {
  content: '\e98a';
}

/* '' */
.fa-file:before {
  content: '\e98b';
}

/* '' */
.fa-fill-drip:before {
  content: '\e98c';
}

/* '' */
.fa-fill:before {
  content: '\e98d';
}

/* '' */
.fa-film-alt:before {
  content: '\e98e';
}

/* '' */
.fa-film:before {
  content: '\e98f';
}

/* '' */
.fa-filter:before {
  content: '\e990';
}

/* '' */
.fa-fingerprint:before {
  content: '\e991';
}

/* '' */
.fa-fire-extinguisher:before {
  content: '\e992';
}

/* '' */
.fa-fire:before {
  content: '\e993';
}

/* '' */
.fa-first-aid:before {
  content: '\e994';
}

/* '' */
.fa-fish:before {
  content: '\e995';
}

/* '' */
.fa-flag-checkered:before {
  content: '\e996';
}

/* '' */
.fa-flag:before {
  content: '\e997';
}

/* '' */
.fa-flask:before {
  content: '\e998';
}

/* '' */
.fa-flushed:before {
  content: '\e999';
}

/* '' */
.fa-folder-open:before {
  content: '\e99a';
}

/* '' */
.fa-folder:before {
  content: '\e99b';
}

/* '' */
.fa-font-awesome-logo-full:before {
  content: '\e99c';
}

/* '' */
.fa-font:before {
  content: '\e99d';
}

/* '' */
.fa-football-ball:before {
  content: '\e99e';
}

/* '' */
.fa-football-helmet:before {
  content: '\e99f';
}

/* '' */
.fa-forklift:before {
  content: '\e9a0';
}

/* '' */
.fa-forward:before {
  content: '\e9a1';
}

/* '' */
.fa-fragile:before {
  content: '\e9a2';
}

/* '' */
.fa-frog:before {
  content: '\e9a3';
}

/* '' */
.fa-frown-open:before {
  content: '\e9a4';
}

/* '' */
.fa-frown:before {
  content: '\e9a5';
}

/* '' */
.fa-futbol:before {
  content: '\e9a6';
}

/* '' */
.fa-gamepad:before {
  content: '\e9a7';
}

/* '' */
.fa-gas-pump:before {
  content: '\e9a8';
}

/* '' */
.fa-gavel:before {
  content: '\e9a9';
}

/* '' */
.fa-gem:before {
  content: '\e9aa';
}

/* '' */
.fa-genderless:before {
  content: '\e9ab';
}

/* '' */
.fa-gift:before {
  content: '\e9ac';
}

/* '' */
.fa-glass-martini-alt:before {
  content: '\e9ad';
}

/* '' */
.fa-glass-martini:before {
  content: '\e9ae';
}

/* '' */
.fa-glasses:before {
  content: '\e9af';
}

/* '' */
.fa-globe-africa:before {
  content: '\e9b0';
}

/* '' */
.fa-globe-americas:before {
  content: '\e9b1';
}

/* '' */
.fa-globe-asia:before {
  content: '\e9b2';
}

/* '' */
.fa-globe:before {
  content: '\e9b3';
}

/* '' */
.fa-golf-ball:before {
  content: '\e9b4';
}

/* '' */
.fa-golf-club:before {
  content: '\e9b5';
}

/* '' */
.fa-graduation-cap:before {
  content: '\e9b6';
}

/* '' */
.fa-greater-than-equal:before {
  content: '\e9b7';
}

/* '' */
.fa-greater-than:before {
  content: '\e9b8';
}

/* '' */
.fa-grimace:before {
  content: '\e9b9';
}

/* '' */
.fa-grin-alt:before {
  content: '\e9ba';
}

/* '' */
.fa-grin-beam-sweat:before {
  content: '\e9bb';
}

/* '' */
.fa-grin-beam:before {
  content: '\e9bc';
}

/* '' */
.fa-grin-hearts:before {
  content: '\e9bd';
}

/* '' */
.fa-grin-squint-tears:before {
  content: '\e9be';
}

/* '' */
.fa-grin-squint:before {
  content: '\e9bf';
}

/* '' */
.fa-grin-stars:before {
  content: '\e9c0';
}

/* '' */
.fa-grin-tears:before {
  content: '\e9c1';
}

/* '' */
.fa-grin-tongue-squint:before {
  content: '\e9c2';
}

/* '' */
.fa-grin-tongue-wink:before {
  content: '\e9c3';
}

/* '' */
.fa-grin-tongue:before {
  content: '\e9c4';
}

/* '' */
.fa-grin-wink:before {
  content: '\e9c5';
}

/* '' */
.fa-grin:before {
  content: '\e9c6';
}

/* '' */
.fa-grip-horizontal:before {
  content: '\e9c7';
}

/* '' */
.fa-grip-vertical:before {
  content: '\e9c8';
}

/* '' */
.fa-h-square:before {
  content: '\e9c9';
}

/* '' */
.fa-h1:before {
  content: '\e9ca';
}

/* '' */
.fa-h2:before {
  content: '\e9cb';
}

/* '' */
.fa-h3:before {
  content: '\e9cc';
}

/* '' */
.fa-hand-heart:before {
  content: '\e9cd';
}

/* '' */
.fa-hand-holding-box:before {
  content: '\e9ce';
}

/* '' */
.fa-hand-holding-heart:before {
  content: '\e9cf';
}

/* '' */
.fa-hand-holding-seedling:before {
  content: '\e9d0';
}

/* '' */
.fa-hand-holding-usd:before {
  content: '\e9d1';
}

/* '' */
.fa-hand-holding-water:before {
  content: '\e9d2';
}

/* '' */
.fa-hand-holding:before {
  content: '\e9d3';
}

/* '' */
.fa-hand-lizard:before {
  content: '\e9d4';
}

/* '' */
.fa-hand-paper:before {
  content: '\e9d5';
}

/* '' */
.fa-hand-peace:before {
  content: '\e9d6';
}

/* '' */
.fa-hand-point-down:before {
  content: '\e9d7';
}

/* '' */
.fa-hand-point-left:before {
  content: '\e9d8';
}

/* '' */
.fa-hand-point-right:before {
  content: '\e9d9';
}

/* '' */
.fa-hand-point-up:before {
  content: '\e9da';
}

/* '' */
.fa-hand-pointer:before {
  content: '\e9db';
}

/* '' */
.fa-hand-receiving:before {
  content: '\e9dc';
}

/* '' */
.fa-hand-rock:before {
  content: '\e9dd';
}

/* '' */
.fa-hand-scissors:before {
  content: '\e9de';
}

/* '' */
.fa-hand-spock:before {
  content: '\e9df';
}

/* '' */
.fa-hands-heart:before {
  content: '\e9e0';
}

/* '' */
.fa-hands-helping:before {
  content: '\e9e1';
}

/* '' */
.fa-hands-usd:before {
  content: '\e9e2';
}

/* '' */
.fa-hands:before {
  content: '\e9e3';
}

/* '' */
.fa-handshake-alt:before {
  content: '\e9e4';
}

/* '' */
.fa-handshake:before {
  content: '\e9e5';
}

/* '' */
.fa-hashtag:before {
  content: '\e9e6';
}

/* '' */
.fa-hdd:before {
  content: '\e9e7';
}

/* '' */
.fa-heading:before {
  content: '\e9e8';
}

/* '' */
.fa-headphones-alt:before {
  content: '\e9e9';
}

/* '' */
.fa-headphones:before {
  content: '\e9ea';
}

/* '' */
.fa-headset:before {
  content: '\e9eb';
}

/* '' */
.fa-heart-circle:before {
  content: '\e9ec';
}

/* '' */
.fa-heart-square:before {
  content: '\e9ed';
}

/* '' */
.fa-heart:before {
  content: '\e9ee';
}

/* '' */
.fa-heartbeat:before {
  content: '\e9ef';
}

/* '' */
.fa-helicopter:before {
  content: '\e9f0';
}

/* '' */
.fa-hexagon:before {
  content: '\e9f1';
}

/* '' */
.fa-highlighter:before {
  content: '\e9f2';
}

/* '' */
.fa-history:before {
  content: '\e9f3';
}

/* '' */
.fa-hockey-puck:before {
  content: '\e9f4';
}

/* '' */
.fa-hockey-sticks:before {
  content: '\e9f5';
}

/* '' */
.fa-home-heart:before {
  content: '\e9f6';
}

/* '' */
.fa-home:before {
  content: '\e9f7';
}

/* '' */
.fa-hospital-alt:before {
  content: '\e9f8';
}

/* '' */
.fa-hospital-symbol:before {
  content: '\e9f9';
}

/* '' */
.fa-hospital:before {
  content: '\e9fa';
}

/* '' */
.fa-hot-tub:before {
  content: '\e9fb';
}

/* '' */
.fa-hotel:before {
  content: '\e9fc';
}

/* '' */
.fa-hourglass-end:before {
  content: '\e9fd';
}

/* '' */
.fa-hourglass-half:before {
  content: '\e9fe';
}

/* '' */
.fa-hourglass-start:before {
  content: '\e9ff';
}

/* '' */
.fa-hourglass:before {
  content: '\ea00';
}

/* '' */
.fa-i-cursor:before {
  content: '\ea01';
}

/* '' */
.fa-id-badge:before {
  content: '\ea02';
}

/* '' */
.fa-id-card-alt:before {
  content: '\ea03';
}

/* '' */
.fa-id-card:before {
  content: '\ea04';
}

/* '' */
.fa-image:before {
  content: '\ea05';
}

/* '' */
.fa-images:before {
  content: '\ea06';
}

/* '' */
.fa-inbox-in:before {
  content: '\ea07';
}

/* '' */
.fa-inbox-out:before {
  content: '\ea08';
}

/* '' */
.fa-inbox:before {
  content: '\ea09';
}

/* '' */
.fa-indent:before {
  content: '\ea0a';
}

/* '' */
.fa-industry-alt:before {
  content: '\ea0b';
}

/* '' */
.fa-industry:before {
  content: '\ea0c';
}

/* '' */
.fa-infinity:before {
  content: '\ea0d';
}

/* '' */
.fa-info-circle:before {
  content: '\ea0e';
}

/* '' */
.fa-info-square:before {
  content: '\ea0f';
}

/* '' */
.fa-info:before {
  content: '\ea10';
}

/* '' */
.fa-inventory:before {
  content: '\ea11';
}

/* '' */
.fa-italic:before {
  content: '\ea12';
}

/* '' */
.fa-jack-o-lantern:before {
  content: '\ea13';
}

/* '' */
.fa-joint:before {
  content: '\ea14';
}

/* '' */
.fa-key:before {
  content: '\ea15';
}

/* '' */
.fa-keyboard:before {
  content: '\ea16';
}

/* '' */
.fa-kiss-beam:before {
  content: '\ea17';
}

/* '' */
.fa-kiss-wink-heart:before {
  content: '\ea18';
}

/* '' */
.fa-kiss:before {
  content: '\ea19';
}

/* '' */
.fa-kiwi-bird:before {
  content: '\ea1a';
}

/* '' */
.fa-lamp:before {
  content: '\ea1b';
}

/* '' */
.fa-language:before {
  content: '\ea1c';
}

/* '' */
.fa-laptop:before {
  content: '\ea1d';
}

/* '' */
.fa-laugh-beam:before {
  content: '\ea1e';
}

/* '' */
.fa-laugh-squint:before {
  content: '\ea1f';
}

/* '' */
.fa-laugh-wink:before {
  content: '\ea20';
}

/* '' */
.fa-laugh:before {
  content: '\ea21';
}

/* '' */
.fa-leaf-heart:before {
  content: '\ea22';
}

/* '' */
.fa-leaf:before {
  content: '\ea23';
}

/* '' */
.fa-lemon:before {
  content: '\ea24';
}

/* '' */
.fa-less-than-equal:before {
  content: '\ea25';
}

/* '' */
.fa-less-than:before {
  content: '\ea26';
}

/* '' */
.fa-level-down-alt:before {
  content: '\ea27';
}

/* '' */
.fa-level-down:before {
  content: '\ea28';
}

/* '' */
.fa-level-up-alt:before {
  content: '\ea29';
}

/* '' */
.fa-level-up:before {
  content: '\ea2a';
}

/* '' */
.fa-life-ring:before {
  content: '\ea2b';
}

/* '' */
.fa-lightbulb:before {
  content: '\ea2c';
}

/* '' */
.fa-link:before {
  content: '\ea2d';
}

/* '' */
.fa-lira-sign:before {
  content: '\ea2e';
}

/* '' */
.fa-list-alt:before {
  content: '\ea2f';
}

/* '' */
.fa-list-ol:before {
  content: '\ea30';
}

/* '' */
.fa-list-ul:before {
  content: '\ea31';
}

/* '' */
.fa-list:before {
  content: '\ea32';
}

/* '' */
.fa-location-arrow:before {
  content: '\ea33';
}

/* '' */
.fa-lock-alt:before {
  content: '\ea34';
}

/* '' */
.fa-lock-open-alt:before {
  content: '\ea35';
}

/* '' */
.fa-lock-open:before {
  content: '\ea36';
}

/* '' */
.fa-lock:before {
  content: '\ea37';
}

/* '' */
.fa-long-arrow-alt-down:before {
  content: '\ea38';
}

/* '' */
.fa-long-arrow-alt-left:before {
  content: '\ea39';
}

/* '' */
.fa-long-arrow-alt-right:before {
  content: '\ea3a';
}

/* '' */
.fa-long-arrow-alt-up:before {
  content: '\ea3b';
}

/* '' */
.fa-long-arrow-down:before {
  content: '\ea3c';
}

/* '' */
.fa-long-arrow-left:before {
  content: '\ea3d';
}

/* '' */
.fa-long-arrow-right:before {
  content: '\ea3e';
}

/* '' */
.fa-long-arrow-up:before {
  content: '\ea3f';
}

/* '' */
.fa-loveseat:before {
  content: '\ea40';
}

/* '' */
.fa-low-vision:before {
  content: '\ea41';
}

/* '' */
.fa-luchador:before {
  content: '\ea42';
}

/* '' */
.fa-luggage-cart:before {
  content: '\ea43';
}

/* '' */
.fa-magic:before {
  content: '\ea44';
}

/* '' */
.fa-magnet:before {
  content: '\ea45';
}

/* '' */
.fa-male:before {
  content: '\ea46';
}

/* '' */
.fa-map-marked-alt:before {
  content: '\ea47';
}

/* '' */
.fa-map-marked:before {
  content: '\ea48';
}

/* '' */
.fa-map-marker-alt:before {
  content: '\ea49';
}

/* '' */
.fa-map-marker:before {
  content: '\ea4a';
}

/* '' */
.fa-map-pin:before {
  content: '\ea4b';
}

/* '' */
.fa-map-signs:before {
  content: '\ea4c';
}

/* '' */
.fa-map:before {
  content: '\ea4d';
}

/* '' */
.fa-marker:before {
  content: '\ea4e';
}

/* '' */
.fa-mars-double:before {
  content: '\ea4f';
}

/* '' */
.fa-mars-stroke-h:before {
  content: '\ea50';
}

/* '' */
.fa-mars-stroke-v:before {
  content: '\ea51';
}

/* '' */
.fa-mars-stroke:before {
  content: '\ea52';
}

/* '' */
.fa-mars:before {
  content: '\ea53';
}

/* '' */
.fa-medal:before {
  content: '\ea54';
}

/* '' */
.fa-medkit:before {
  content: '\ea55';
}

/* '' */
.fa-meh-blank:before {
  content: '\ea56';
}

/* '' */
.fa-meh-rolling-eyes:before {
  content: '\ea57';
}

/* '' */
.fa-meh:before {
  content: '\ea58';
}

/* '' */
.fa-memory:before {
  content: '\ea59';
}

/* '' */
.fa-mercury:before {
  content: '\ea5a';
}

/* '' */
.fa-microchip:before {
  content: '\ea5b';
}

/* '' */
.fa-microphone-alt-slash:before {
  content: '\ea5c';
}

/* '' */
.fa-microphone-alt:before {
  content: '\ea5d';
}

/* '' */
.fa-microphone-slash:before {
  content: '\ea5e';
}

/* '' */
.fa-microphone:before {
  content: '\ea5f';
}

/* '' */
.fa-minus-circle:before {
  content: '\ea60';
}

/* '' */
.fa-minus-hexagon:before {
  content: '\ea61';
}

/* '' */
.fa-minus-octagon:before {
  content: '\ea62';
}

/* '' */
.fa-minus-square:before {
  content: '\ea63';
}

/* '' */
.fa-minus:before {
  content: '\ea64';
}

/* '' */
.fa-mobile-alt:before {
  content: '\ea65';
}

/* '' */
.fa-mobile-android-alt:before {
  content: '\ea66';
}

/* '' */
.fa-mobile-android:before {
  content: '\ea67';
}

/* '' */
.fa-mobile:before {
  content: '\ea68';
}

/* '' */
.fa-money-bill-alt:before {
  content: '\ea69';
}

/* '' */
.fa-money-bill-wave-alt:before {
  content: '\ea6a';
}

/* '' */
.fa-money-bill-wave:before {
  content: '\ea6b';
}

/* '' */
.fa-money-bill:before {
  content: '\ea6c';
}

/* '' */
.fa-money-check-alt:before {
  content: '\ea6d';
}

/* '' */
.fa-money-check:before {
  content: '\ea6e';
}

/* '' */
.fa-monument:before {
  content: '\ea6f';
}

/* '' */
.fa-moon:before {
  content: '\ea70';
}

/* '' */
.fa-mortar-pestle:before {
  content: '\ea71';
}

/* '' */
.fa-motorcycle:before {
  content: '\ea72';
}

/* '' */
.fa-mouse-pointer:before {
  content: '\ea73';
}

/* '' */
.fa-music:before {
  content: '\ea74';
}

/* '' */
.fa-neuter:before {
  content: '\ea75';
}

/* '' */
.fa-newspaper:before {
  content: '\ea76';
}

/* '' */
.fa-not-equal:before {
  content: '\ea77';
}

/* '' */
.fa-notes-medical:before {
  content: '\ea78';
}

/* '' */
.fa-object-group:before {
  content: '\ea79';
}

/* '' */
.fa-object-ungroup:before {
  content: '\ea7a';
}

/* '' */
.fa-octagon:before {
  content: '\ea7b';
}

/* '' */
.fa-outdent:before {
  content: '\ea7c';
}

/* '' */
.fa-paint-brush-alt:before {
  content: '\ea7d';
}

/* '' */
.fa-paint-brush:before {
  content: '\ea7e';
}

/* '' */
.fa-paint-roller:before {
  content: '\ea7f';
}

/* '' */
.fa-palette:before {
  content: '\ea80';
}

/* '' */
.fa-pallet-alt:before {
  content: '\ea81';
}

/* '' */
.fa-pallet:before {
  content: '\ea82';
}

/* '' */
.fa-paper-plane:before {
  content: '\ea83';
}

/* '' */
.fa-paperclip:before {
  content: '\ea84';
}

/* '' */
.fa-parachute-box:before {
  content: '\ea85';
}

/* '' */
.fa-paragraph:before {
  content: '\ea86';
}

/* '' */
.fa-parking:before {
  content: '\ea87';
}

/* '' */
.fa-passport:before {
  content: '\ea88';
}

/* '' */
.fa-paste:before {
  content: '\ea89';
}

/* '' */
.fa-pause-circle:before {
  content: '\ea8a';
}

/* '' */
.fa-pause:before {
  content: '\ea8b';
}

/* '' */
.fa-paw:before {
  content: '\ea8c';
}

/* '' */
.fa-pen-alt:before {
  content: '\ea8d';
}

/* '' */
.fa-pen-fancy:before {
  content: '\ea8e';
}

/* '' */
.fa-pen-nib:before {
  content: '\ea8f';
}

/* '' */
.fa-pen-square:before {
  content: '\ea90';
}

/* '' */
.fa-pen:before {
  content: '\ea91';
}

/* '' */
.fa-pencil-alt:before {
  content: '\ea92';
}

/* '' */
.fa-pencil-ruler:before {
  content: '\ea93';
}

/* '' */
.fa-pencil:before {
  content: '\ea94';
}

/* '' */
.fa-pennant:before {
  content: '\ea95';
}

/* '' */
.fa-people-carry:before {
  content: '\ea96';
}

/* '' */
.fa-percent:before {
  content: '\ea97';
}

/* '' */
.fa-percentage:before {
  content: '\ea98';
}

/* '' */
.fa-person-carry:before {
  content: '\ea99';
}

/* '' */
.fa-person-dolly-empty:before {
  content: '\ea9a';
}

/* '' */
.fa-person-dolly:before {
  content: '\ea9b';
}

/* '' */
.fa-phone-plus:before {
  content: '\ea9c';
}

/* '' */
.fa-phone-slash:before {
  content: '\ea9d';
}

/* '' */
.fa-phone-square:before {
  content: '\ea9e';
}

/* '' */
.fa-phone-volume:before {
  content: '\ea9f';
}

/* '' */
.fa-phone:before {
  content: '\eaa0';
}

/* '' */
.fa-piggy-bank:before {
  content: '\eaa1';
}

/* '' */
.fa-pills:before {
  content: '\eaa2';
}

/* '' */
.fa-plane-alt:before {
  content: '\eaa3';
}

/* '' */
.fa-plane-arrival:before {
  content: '\eaa4';
}

/* '' */
.fa-plane-departure:before {
  content: '\eaa5';
}

/* '' */
.fa-plane:before {
  content: '\eaa6';
}

/* '' */
.fa-play-circle:before {
  content: '\eaa7';
}

/* '' */
.fa-play:before {
  content: '\eaa8';
}

/* '' */
.fa-plug:before {
  content: '\eaa9';
}

/* '' */
.fa-plus-circle:before {
  content: '\eaaa';
}

/* '' */
.fa-plus-hexagon:before {
  content: '\eaab';
}

/* '' */
.fa-plus-octagon:before {
  content: '\eaac';
}

/* '' */
.fa-plus-square:before {
  content: '\eaad';
}

/* '' */
.fa-plus:before {
  content: '\eaae';
}

/* '' */
.fa-podcast:before {
  content: '\eaaf';
}

/* '' */
.fa-poo:before {
  content: '\eab0';
}

/* '' */
.fa-portrait:before {
  content: '\eab1';
}

/* '' */
.fa-pound-sign:before {
  content: '\eab2';
}

/* '' */
.fa-power-off:before {
  content: '\eab3';
}

/* '' */
.fa-prescription-bottle-alt:before {
  content: '\eab4';
}

/* '' */
.fa-prescription-bottle:before {
  content: '\eab5';
}

/* '' */
.fa-prescription:before {
  content: '\eab6';
}

/* '' */
.fa-print:before {
  content: '\eab7';
}

/* '' */
.fa-procedures:before {
  content: '\eab8';
}

/* '' */
.fa-project-diagram:before {
  content: '\eab9';
}

/* '' */
.fa-puzzle-piece:before {
  content: '\eaba';
}

/* '' */
.fa-qrcode:before {
  content: '\eabb';
}

/* '' */
.fa-question-circle:before {
  content: '\eabc';
}

/* '' */
.fa-question-square:before {
  content: '\eabd';
}

/* '' */
.fa-question:before {
  content: '\eabe';
}

/* '' */
.fa-quidditch:before {
  content: '\eabf';
}

/* '' */
.fa-quote-left:before {
  content: '\eac0';
}

/* '' */
.fa-quote-right:before {
  content: '\eac1';
}

/* '' */
.fa-racquet:before {
  content: '\eac2';
}

/* '' */
.fa-ramp-loading:before {
  content: '\eac3';
}

/* '' */
.fa-random:before {
  content: '\eac4';
}

/* '' */
.fa-receipt:before {
  content: '\eac5';
}

/* '' */
.fa-rectangle-landscape:before {
  content: '\eac6';
}

/* '' */
.fa-rectangle-portrait:before {
  content: '\eac7';
}

/* '' */
.fa-rectangle-wide:before {
  content: '\eac8';
}

/* '' */
.fa-recycle:before {
  content: '\eac9';
}

/* '' */
.fa-redo-alt:before {
  content: '\eaca';
}

/* '' */
.fa-redo:before {
  content: '\eacb';
}

/* '' */
.fa-registered:before {
  content: '\eacc';
}

/* '' */
.fa-repeat-1-alt:before {
  content: '\eacd';
}

/* '' */
.fa-repeat-1:before {
  content: '\eace';
}

/* '' */
.fa-repeat-alt:before {
  content: '\eacf';
}

/* '' */
.fa-repeat:before {
  content: '\ead0';
}

/* '' */
.fa-reply-all:before {
  content: '\ead1';
}

/* '' */
.fa-reply:before {
  content: '\ead2';
}

/* '' */
.fa-retweet-alt:before {
  content: '\ead3';
}

/* '' */
.fa-retweet:before {
  content: '\ead4';
}

/* '' */
.fa-ribbon:before {
  content: '\ead5';
}

/* '' */
.fa-road:before {
  content: '\ead6';
}

/* '' */
.fa-robot:before {
  content: '\ead7';
}

/* '' */
.fa-rocket:before {
  content: '\ead8';
}

/* '' */
.fa-route:before {
  content: '\ead9';
}

/* '' */
.fa-rss-square:before {
  content: '\eada';
}

/* '' */
.fa-rss:before {
  content: '\eadb';
}

/* '' */
.fa-ruble-sign:before {
  content: '\eadc';
}

/* '' */
.fa-ruler-combined:before {
  content: '\eadd';
}

/* '' */
.fa-ruler-horizontal:before {
  content: '\eade';
}

/* '' */
.fa-ruler-vertical:before {
  content: '\eadf';
}

/* '' */
.fa-ruler:before {
  content: '\eae0';
}

/* '' */
.fa-rupee-sign:before {
  content: '\eae1';
}

/* '' */
.fa-sad-cry:before {
  content: '\eae2';
}

/* '' */
.fa-sad-tear:before {
  content: '\eae3';
}

/* '' */
.fa-save:before {
  content: '\eae4';
}

/* '' */
.fa-scanner-keyboard:before {
  content: '\eae5';
}

/* '' */
.fa-scanner-touchscreen:before {
  content: '\eae6';
}

/* '' */
.fa-scanner:before {
  content: '\eae7';
}

/* '' */
.fa-school:before {
  content: '\eae8';
}

/* '' */
.fa-screwdriver:before {
  content: '\eae9';
}

/* '' */
.fa-scrubber:before {
  content: '\eaea';
}

/* '' */
.fa-search-minus:before {
  content: '\eaeb';
}

/* '' */
.fa-search-plus:before {
  content: '\eaec';
}

/* '' */
.fa-search:before {
  content: '\eaed';
}

/* '' */
.fa-seedling:before {
  content: '\eaee';
}

/* '' */
.fa-server:before {
  content: '\eaef';
}

/* '' */
.fa-share-all:before {
  content: '\eaf0';
}

/* '' */
.fa-share-alt-square:before {
  content: '\eaf1';
}

/* '' */
.fa-share-alt:before {
  content: '\eaf2';
}

/* '' */
.fa-share-square:before {
  content: '\eaf3';
}

/* '' */
.fa-share:before {
  content: '\eaf4';
}

/* '' */
.fa-shekel-sign:before {
  content: '\eaf5';
}

/* '' */
.fa-shield-alt:before {
  content: '\eaf6';
}

/* '' */
.fa-shield-check:before {
  content: '\eaf7';
}

/* '' */
.fa-shield:before {
  content: '\eaf8';
}

/* '' */
.fa-ship:before {
  content: '\eaf9';
}

/* '' */
.fa-shipping-fast:before {
  content: '\eafa';
}

/* '' */
.fa-shipping-timed:before {
  content: '\eafb';
}

/* '' */
.fa-shoe-prints:before {
  content: '\eafc';
}

/* '' */
.fa-shopping-bag:before {
  content: '\eafd';
}

/* '' */
.fa-shopping-basket:before {
  content: '\eafe';
}

/* '' */
.fa-shopping-cart:before {
  content: '\eaff';
}

/* '' */
.fa-shower:before {
  content: '\eb00';
}

/* '' */
.fa-shuttle-van:before {
  content: '\eb01';
}

/* '' */
.fa-shuttlecock:before {
  content: '\eb02';
}

/* '' */
.fa-sign-in-alt:before {
  content: '\eb03';
}

/* '' */
.fa-sign-in:before {
  content: '\eb04';
}

/* '' */
.fa-sign-language:before {
  content: '\eb05';
}

/* '' */
.fa-sign-out-alt:before {
  content: '\eb06';
}

/* '' */
.fa-sign-out:before {
  content: '\eb07';
}

/* '' */
.fa-sign:before {
  content: '\eb08';
}

/* '' */
.fa-signal:before {
  content: '\eb09';
}

/* '' */
.fa-signature:before {
  content: '\eb0a';
}

/* '' */
.fa-sitemap:before {
  content: '\eb0b';
}

/* '' */
.fa-skull:before {
  content: '\eb0c';
}

/* '' */
.fa-sliders-h-square:before {
  content: '\eb0d';
}

/* '' */
.fa-sliders-h:before {
  content: '\eb0e';
}

/* '' */
.fa-sliders-v-square:before {
  content: '\eb0f';
}

/* '' */
.fa-sliders-v:before {
  content: '\eb10';
}

/* '' */
.fa-smile-beam:before {
  content: '\eb11';
}

/* '' */
.fa-smile-plus:before {
  content: '\eb12';
}

/* '' */
.fa-smile-wink:before {
  content: '\eb13';
}

/* '' */
.fa-smile:before {
  content: '\eb14';
}

/* '' */
.fa-smoking-ban:before {
  content: '\eb15';
}

/* '' */
.fa-smoking:before {
  content: '\eb16';
}

/* '' */
.fa-snowflake:before {
  content: '\eb17';
}

/* '' */
.fa-solar-panel:before {
  content: '\eb18';
}

/* '' */
.fa-sort-alpha-down:before {
  content: '\eb19';
}

/* '' */
.fa-sort-alpha-up:before {
  content: '\eb1a';
}

/* '' */
.fa-sort-amount-down:before {
  content: '\eb1b';
}

/* '' */
.fa-sort-amount-up:before {
  content: '\eb1c';
}

/* '' */
.fa-sort-down:before {
  content: '\eb1d';
}

/* '' */
.fa-sort-numeric-down:before {
  content: '\eb1e';
}

/* '' */
.fa-sort-numeric-up:before {
  content: '\eb1f';
}

/* '' */
.fa-sort-up:before {
  content: '\eb20';
}

/* '' */
.fa-sort:before {
  content: '\eb21';
}

/* '' */
.fa-spa:before {
  content: '\eb22';
}

/* '' */
.fa-space-shuttle:before {
  content: '\eb23';
}

/* '' */
.fa-spade:before {
  content: '\eb24';
}

/* '' */
.fa-spinner-third:before {
  content: '\eb25';
}

/* '' */
.fa-spinner:before {
  content: '\eb26';
}

/* '' */
.fa-splotch:before {
  content: '\eb27';
}

/* '' */
.fa-spray-can:before {
  content: '\eb28';
}

/* '' */
.fa-square-full:before {
  content: '\eb29';
}

/* '' */
.fa-square:before {
  content: '\eb2a';
}

/* '' */
.fa-stamp:before {
  content: '\eb2b';
}

/* '' */
.fa-star-exclamation:before {
  content: '\eb2c';
}

/* '' */
.fa-star-half-alt:before {
  content: '\eb2d';
}

/* '' */
.fa-star-half:before {
  content: '\eb2e';
}

/* '' */
.fa-star:before {
  content: '\eb2f';
}

/* '' */
.fa-step-backward:before {
  content: '\eb30';
}

/* '' */
.fa-step-forward:before {
  content: '\eb31';
}

/* '' */
.fa-stethoscope:before {
  content: '\eb32';
}

/* '' */
.fa-sticky-note:before {
  content: '\eb33';
}

/* '' */
.fa-stop-circle:before {
  content: '\eb34';
}

/* '' */
.fa-stop:before {
  content: '\eb35';
}

/* '' */
.fa-stopwatch:before {
  content: '\eb36';
}

/* '' */
.fa-store-alt:before {
  content: '\eb37';
}

/* '' */
.fa-store:before {
  content: '\eb38';
}

/* '' */
.fa-stream:before {
  content: '\eb39';
}

/* '' */
.fa-street-view:before {
  content: '\eb3a';
}

/* '' */
.fa-strikethrough:before {
  content: '\eb3b';
}

/* '' */
.fa-stroopwafel:before {
  content: '\eb3c';
}

/* '' */
.fa-subscript:before {
  content: '\eb3d';
}

/* '' */
.fa-subway:before {
  content: '\eb3e';
}

/* '' */
.fa-suitcase-rolling:before {
  content: '\eb3f';
}

/* '' */
.fa-suitcase:before {
  content: '\eb40';
}

/* '' */
.fa-sun:before {
  content: '\eb41';
}

/* '' */
.fa-superscript:before {
  content: '\eb42';
}

/* '' */
.fa-surprise:before {
  content: '\eb43';
}

/* '' */
.fa-swatchbook:before {
  content: '\eb44';
}

/* '' */
.fa-swimmer:before {
  content: '\eb45';
}

/* '' */
.fa-swimming-pool:before {
  content: '\eb46';
}

/* '' */
.fa-sync-alt:before {
  content: '\eb47';
}

/* '' */
.fa-sync:before {
  content: '\eb48';
}

/* '' */
.fa-syringe:before {
  content: '\eb49';
}

/* '' */
.fa-table-tennis:before {
  content: '\eb4a';
}

/* '' */
.fa-table:before {
  content: '\eb4b';
}

/* '' */
.fa-tablet-alt:before {
  content: '\eb4c';
}

/* '' */
.fa-tablet-android-alt:before {
  content: '\eb4d';
}

/* '' */
.fa-tablet-android:before {
  content: '\eb4e';
}

/* '' */
.fa-tablet-rugged:before {
  content: '\eb4f';
}

/* '' */
.fa-tablet:before {
  content: '\eb50';
}

/* '' */
.fa-tablets:before {
  content: '\eb51';
}

/* '' */
.fa-tachometer-alt:before {
  content: '\eb52';
}

/* '' */
.fa-tachometer:before {
  content: '\eb53';
}

/* '' */
.fa-tag:before {
  content: '\eb54';
}

/* '' */
.fa-tags:before {
  content: '\eb55';
}

/* '' */
.fa-tape:before {
  content: '\eb56';
}

/* '' */
.fa-tasks:before {
  content: '\eb57';
}

/* '' */
.fa-taxi:before {
  content: '\eb58';
}

/* '' */
.fa-tennis-ball:before {
  content: '\eb59';
}

/* '' */
.fa-terminal:before {
  content: '\eb5a';
}

/* '' */
.fa-text-height:before {
  content: '\eb5b';
}

/* '' */
.fa-text-width:before {
  content: '\eb5c';
}

/* '' */
.fa-th-large:before {
  content: '\eb5d';
}

/* '' */
.fa-th-list:before {
  content: '\eb5e';
}

/* '' */
.fa-th:before {
  content: '\eb5f';
}

/* '' */
.fa-thermometer-empty:before {
  content: '\eb60';
}

/* '' */
.fa-thermometer-full:before {
  content: '\eb61';
}

/* '' */
.fa-thermometer-half:before {
  content: '\eb62';
}

/* '' */
.fa-thermometer-quarter:before {
  content: '\eb63';
}

/* '' */
.fa-thermometer-three-quarters:before {
  content: '\eb64';
}

/* '' */
.fa-thermometer:before {
  content: '\eb65';
}

/* '' */
.fa-thumbs-down:before {
  content: '\eb66';
}

/* '' */
.fa-thumbs-up:before {
  content: '\eb67';
}

/* '' */
.fa-thumbtack:before {
  content: '\eb68';
}

/* '' */
.fa-ticket-alt:before {
  content: '\eb69';
}

/* '' */
.fa-ticket:before {
  content: '\eb6a';
}

/* '' */
.fa-times-circle:before {
  content: '\eb6b';
}

/* '' */
.fa-times-hexagon:before {
  content: '\eb6c';
}

/* '' */
.fa-times-octagon:before {
  content: '\eb6d';
}

/* '' */
.fa-times-square:before {
  content: '\eb6e';
}

/* '' */
.fa-times:before {
  content: '\eb6f';
}

/* '' */
.fa-tint-slash:before {
  content: '\eb70';
}

/* '' */
.fa-tint:before {
  content: '\eb71';
}

/* '' */
.fa-tired:before {
  content: '\eb72';
}

/* '' */
.fa-toggle-off:before {
  content: '\eb73';
}

/* '' */
.fa-toggle-on:before {
  content: '\eb74';
}

/* '' */
.fa-toolbox:before {
  content: '\eb75';
}

/* '' */
.fa-tooth:before {
  content: '\eb76';
}

/* '' */
.fa-trademark:before {
  content: '\eb77';
}

/* '' */
.fa-train:before {
  content: '\eb78';
}

/* '' */
.fa-transgender-alt:before {
  content: '\eb79';
}

/* '' */
.fa-transgender:before {
  content: '\eb7a';
}

/* '' */
.fa-trash-alt:before {
  content: '\eb7b';
}

/* '' */
.fa-trash:before {
  content: '\eb7c';
}

/* '' */
.fa-tree-alt:before {
  content: '\eb7d';
}

/* '' */
.fa-tree:before {
  content: '\eb7e';
}

/* '' */
.fa-triangle:before {
  content: '\eb7f';
}

/* '' */
.fa-trophy-alt:before {
  content: '\eb80';
}

/* '' */
.fa-trophy:before {
  content: '\eb81';
}

/* '' */
.fa-truck-container:before {
  content: '\eb82';
}

/* '' */
.fa-truck-couch:before {
  content: '\eb83';
}

/* '' */
.fa-truck-loading:before {
  content: '\eb84';
}

/* '' */
.fa-truck-moving:before {
  content: '\eb85';
}

/* '' */
.fa-truck-ramp:before {
  content: '\eb86';
}

/* '' */
.fa-truck:before {
  content: '\eb87';
}

/* '' */
.fa-tshirt:before {
  content: '\eb88';
}

/* '' */
.fa-tty:before {
  content: '\eb89';
}

/* '' */
.fa-tv-retro:before {
  content: '\eb8a';
}

/* '' */
.fa-tv:before {
  content: '\eb8b';
}

/* '' */
.fa-umbrella-beach:before {
  content: '\eb8c';
}

/* '' */
.fa-umbrella:before {
  content: '\eb8d';
}

/* '' */
.fa-underline:before {
  content: '\eb8e';
}

/* '' */
.fa-undo-alt:before {
  content: '\eb8f';
}

/* '' */
.fa-undo:before {
  content: '\eb90';
}

/* '' */
.fa-universal-access:before {
  content: '\eb91';
}

/* '' */
.fa-university:before {
  content: '\eb92';
}

/* '' */
.fa-unlink:before {
  content: '\eb93';
}

/* '' */
.fa-unlock-alt:before {
  content: '\eb94';
}

/* '' */
.fa-unlock:before {
  content: '\eb95';
}

/* '' */
.fa-upload:before {
  content: '\eb96';
}

/* '' */
.fa-usd-circle:before {
  content: '\eb97';
}

/* '' */
.fa-usd-square:before {
  content: '\eb98';
}

/* '' */
.fa-user-alt-slash:before {
  content: '\eb99';
}

/* '' */
.fa-user-alt:before {
  content: '\eb9a';
}

/* '' */
.fa-user-astronaut:before {
  content: '\eb9b';
}

/* '' */
.fa-user-check:before {
  content: '\eb9c';
}

/* '' */
.fa-user-circle:before {
  content: '\eb9d';
}

/* '' */
.fa-user-clock:before {
  content: '\eb9e';
}

/* '' */
.fa-user-cog:before {
  content: '\eb9f';
}

/* '' */
.fa-user-edit:before {
  content: '\eba0';
}

/* '' */
.fa-user-friends:before {
  content: '\eba1';
}

/* '' */
.fa-user-graduate:before {
  content: '\eba2';
}

/* '' */
.fa-user-lock:before {
  content: '\eba3';
}

/* '' */
.fa-user-md:before {
  content: '\eba4';
}

/* '' */
.fa-user-minus:before {
  content: '\eba5';
}

/* '' */
.fa-user-ninja:before {
  content: '\eba6';
}

/* '' */
.fa-user-plus:before {
  content: '\eba7';
}

/* '' */
.fa-user-secret:before {
  content: '\eba8';
}

/* '' */
.fa-user-shield:before {
  content: '\eba9';
}

/* '' */
.fa-user-slash:before {
  content: '\ebaa';
}

/* '' */
.fa-user-tag:before {
  content: '\ebab';
}

/* '' */
.fa-user-tie:before {
  content: '\ebac';
}

/* '' */
.fa-user-times:before {
  content: '\ebad';
}

/* '' */
.fa-user:before {
  content: '\ebae';
}

/* '' */
.fa-users-cog:before {
  content: '\ebaf';
}

/* '' */
.fa-users:before {
  content: '\ebb0';
}

/* '' */
.fa-utensil-fork:before {
  content: '\ebb1';
}

/* '' */
.fa-utensil-knife:before {
  content: '\ebb2';
}

/* '' */
.fa-utensil-spoon:before {
  content: '\ebb3';
}

/* '' */
.fa-utensils-alt:before {
  content: '\ebb4';
}

/* '' */
.fa-utensils:before {
  content: '\ebb5';
}

/* '' */
.fa-vector-square:before {
  content: '\ebb6';
}

/* '' */
.fa-venus-double:before {
  content: '\ebb7';
}

/* '' */
.fa-venus-mars:before {
  content: '\ebb8';
}

/* '' */
.fa-venus:before {
  content: '\ebb9';
}

/* '' */
.fa-vial:before {
  content: '\ebba';
}

/* '' */
.fa-vials:before {
  content: '\ebbb';
}

/* '' */
.fa-video-plus:before {
  content: '\ebbc';
}

/* '' */
.fa-video-slash:before {
  content: '\ebbd';
}

/* '' */
.fa-video:before {
  content: '\ebbe';
}

/* '' */
.fa-volleyball-ball:before {
  content: '\ebbf';
}

/* '' */
.fa-volume-down:before {
  content: '\ebc0';
}

/* '' */
.fa-volume-mute:before {
  content: '\ebc1';
}

/* '' */
.fa-volume-off:before {
  content: '\ebc2';
}

/* '' */
.fa-volume-up:before {
  content: '\ebc3';
}

/* '' */
.fa-walking:before {
  content: '\ebc4';
}

/* '' */
.fa-wallet:before {
  content: '\ebc5';
}

/* '' */
.fa-warehouse-alt:before {
  content: '\ebc6';
}

/* '' */
.fa-warehouse:before {
  content: '\ebc7';
}

/* '' */
.fa-watch:before {
  content: '\ebc8';
}

/* '' */
.fa-weight-hanging:before {
  content: '\ebc9';
}

/* '' */
.fa-weight:before {
  content: '\ebca';
}

/* '' */
.fa-wheelchair:before {
  content: '\ebcb';
}

/* '' */
.fa-whistle:before {
  content: '\ebcc';
}

/* '' */
.fa-wifi:before {
  content: '\ebcd';
}

/* '' */
.fa-window-alt:before {
  content: '\ebce';
}

/* '' */
.fa-window-close:before {
  content: '\ebcf';
}

/* '' */
.fa-window-maximize:before {
  content: '\ebd0';
}

/* '' */
.fa-window-minimize:before {
  content: '\ebd1';
}

/* '' */
.fa-window-restore:before {
  content: '\ebd2';
}

/* '' */
.fa-window:before {
  content: '\ebd3';
}

/* '' */
.fa-wine-glass-alt:before {
  content: '\ebd4';
}

/* '' */
.fa-wine-glass:before {
  content: '\ebd5';
}

/* '' */
.fa-won-sign:before {
  content: '\ebd6';
}

/* '' */
.fa-wrench:before {
  content: '\ebd7';
}

/* '' */
.fa-x-ray:before {
  content: '\ebd8';
}

/* '' */
.fa-yen-sign:before {
  content: '\ebd9';
}

/* '' */
.fa-500px:before {
  content: '\ebda';
}

/* '' */
.fa-accessible-icon:before {
  content: '\ebdb';
}

/* '' */
.fa-accusoft:before {
  content: '\ebdc';
}

/* '' */
.fa-adn:before {
  content: '\ebdd';
}

/* '' */
.fa-adversal:before {
  content: '\ebde';
}

/* '' */
.fa-affiliatetheme:before {
  content: '\ebdf';
}

/* '' */
.fa-algolia:before {
  content: '\ebe0';
}

/* '' */
.fa-amazon-pay:before {
  content: '\ebe1';
}

/* '' */
.fa-amazon:before {
  content: '\ebe2';
}

/* '' */
.fa-amilia:before {
  content: '\ebe3';
}

/* '' */
.fa-android:before {
  content: '\ebe4';
}

/* '' */
.fa-angellist:before {
  content: '\ebe5';
}

/* '' */
.fa-angrycreative:before {
  content: '\ebe6';
}

/* '' */
.fa-angular:before {
  content: '\ebe7';
}

/* '' */
.fa-app-store-ios:before {
  content: '\ebe8';
}

/* '' */
.fa-app-store:before {
  content: '\ebe9';
}

/* '' */
.fa-apper:before {
  content: '\ebea';
}

/* '' */
.fa-apple-pay:before {
  content: '\ebeb';
}

/* '' */
.fa-apple:before {
  content: '\ebec';
}

/* '' */
.fa-asymmetrik:before {
  content: '\ebed';
}

/* '' */
.fa-audible:before {
  content: '\ebee';
}

/* '' */
.fa-autoprefixer:before {
  content: '\ebef';
}

/* '' */
.fa-avianex:before {
  content: '\ebf0';
}

/* '' */
.fa-aviato:before {
  content: '\ebf1';
}

/* '' */
.fa-aws:before {
  content: '\ebf2';
}

/* '' */
.fa-bandcamp:before {
  content: '\ebf3';
}

/* '' */
.fa-behance-square:before {
  content: '\ebf4';
}

/* '' */
.fa-behance:before {
  content: '\ebf5';
}

/* '' */
.fa-bimobject:before {
  content: '\ebf6';
}

/* '' */
.fa-bitbucket:before {
  content: '\ebf7';
}

/* '' */
.fa-bitcoin:before {
  content: '\ebf8';
}

/* '' */
.fa-bity:before {
  content: '\ebf9';
}

/* '' */
.fa-black-tie:before {
  content: '\ebfa';
}

/* '' */
.fa-blackberry:before {
  content: '\ebfb';
}

/* '' */
.fa-blogger-b:before {
  content: '\ebfc';
}

/* '' */
.fa-blogger:before {
  content: '\ebfd';
}

/* '' */
.fa-bluetooth-b:before {
  content: '\ebfe';
}

/* '' */
.fa-bluetooth:before {
  content: '\ebff';
}

/* '' */
.fa-btc:before {
  content: '\ec00';
}

/* '' */
.fa-buromobelexperte:before {
  content: '\ec01';
}

/* '' */
.fa-buysellads:before {
  content: '\ec02';
}

/* '' */
.fa-cc-amazon-pay:before {
  content: '\ec03';
}

/* '' */
.fa-cc-amex:before {
  content: '\ec04';
}

/* '' */
.fa-cc-apple-pay:before {
  content: '\ec05';
}

/* '' */
.fa-cc-diners-club:before {
  content: '\ec06';
}

/* '' */
.fa-cc-discover:before {
  content: '\ec07';
}

/* '' */
.fa-cc-jcb:before {
  content: '\ec08';
}

/* '' */
.fa-cc-mastercard:before {
  content: '\ec09';
}

/* '' */
.fa-cc-paypal:before {
  content: '\ec0a';
}

/* '' */
.fa-cc-stripe:before {
  content: '\ec0b';
}

/* '' */
.fa-cc-visa:before {
  content: '\ec0c';
}

/* '' */
.fa-centercode:before {
  content: '\ec0d';
}

/* '' */
.fa-chrome:before {
  content: '\ec0e';
}

/* '' */
.fa-cloudscale:before {
  content: '\ec0f';
}

/* '' */
.fa-cloudsmith:before {
  content: '\ec10';
}

/* '' */
.fa-cloudversify:before {
  content: '\ec11';
}

/* '' */
.fa-codepen:before {
  content: '\ec12';
}

/* '' */
.fa-codiepie:before {
  content: '\ec13';
}

/* '' */
.fa-connectdevelop:before {
  content: '\ec14';
}

/* '' */
.fa-contao:before {
  content: '\ec15';
}

/* '' */
.fa-cpanel:before {
  content: '\ec16';
}

/* '' */
.fa-creative-commons-by:before {
  content: '\ec17';
}

/* '' */
.fa-creative-commons-nc-eu:before {
  content: '\ec18';
}

/* '' */
.fa-creative-commons-nc-jp:before {
  content: '\ec19';
}

/* '' */
.fa-creative-commons-nc:before {
  content: '\ec1a';
}

/* '' */
.fa-creative-commons-nd:before {
  content: '\ec1b';
}

/* '' */
.fa-creative-commons-pd-alt:before {
  content: '\ec1c';
}

/* '' */
.fa-creative-commons-pd:before {
  content: '\ec1d';
}

/* '' */
.fa-creative-commons-sa:before {
  content: '\ec1e';
}

/* '' */
.fa-creative-commons-remix:before {
  content: '\ec1f';
}

/* '' */
.fa-creative-commons-sampling-plus:before {
  content: '\ec20';
}

/* '' */
.fa-creative-commons-sampling:before {
  content: '\ec21';
}

/* '' */
.fa-creative-commons-share:before {
  content: '\ec22';
}

/* '' */
.fa-creative-commons-zero:before {
  content: '\ec23';
}

/* '' */
.fa-creative-commons:before {
  content: '\ec24';
}

/* '' */
.fa-css3-alt:before {
  content: '\ec25';
}

/* '' */
.fa-css3:before {
  content: '\ec26';
}

/* '' */
.fa-cuttlefish:before {
  content: '\ec27';
}

/* '' */
.fa-d-and-d:before {
  content: '\ec28';
}

/* '' */
.fa-dashcube:before {
  content: '\ec29';
}

/* '' */
.fa-delicious:before {
  content: '\ec2a';
}

/* '' */
.fa-deploydog:before {
  content: '\ec2b';
}

/* '' */
.fa-deskpro:before {
  content: '\ec2c';
}

/* '' */
.fa-deviantart:before {
  content: '\ec2d';
}

/* '' */
.fa-digg:before {
  content: '\ec2e';
}

/* '' */
.fa-digital-ocean:before {
  content: '\ec2f';
}

/* '' */
.fa-discord:before {
  content: '\ec30';
}

/* '' */
.fa-discourse:before {
  content: '\ec31';
}

/* '' */
.fa-dochub:before {
  content: '\ec32';
}

/* '' */
.fa-docker:before {
  content: '\ec33';
}

/* '' */
.fa-draft2digital:before {
  content: '\ec34';
}

/* '' */
.fa-dribbble-square:before {
  content: '\ec35';
}

/* '' */
.fa-dribbble:before {
  content: '\ec36';
}

/* '' */
.fa-dropbox:before {
  content: '\ec37';
}

/* '' */
.fa-drupal:before {
  content: '\ec38';
}

/* '' */
.fa-dyalog:before {
  content: '\ec39';
}

/* '' */
.fa-earlybirds:before {
  content: '\ec3a';
}

/* '' */
.fa-ebay:before {
  content: '\ec3b';
}

/* '' */
.fa-edge:before {
  content: '\ec3c';
}

/* '' */
.fa-elementor:before {
  content: '\ec3d';
}

/* '' */
.fa-ember:before {
  content: '\ec3e';
}

/* '' */
.fa-empire:before {
  content: '\ec3f';
}

/* '' */
.fa-envira:before {
  content: '\ec40';
}

/* '' */
.fa-erlang:before {
  content: '\ec41';
}

/* '' */
.fa-ethereum:before {
  content: '\ec42';
}

/* '' */
.fa-etsy:before {
  content: '\ec43';
}

/* '' */
.fa-expeditedssl:before {
  content: '\ec44';
}

/* '' */
.fa-facebook-f:before {
  content: '\ec45';
}

/* '' */
.fa-facebook-messenger:before {
  content: '\ec46';
}

/* '' */
.fa-facebook-square:before {
  content: '\ec47';
}

/* '' */
.fa-facebook:before {
  content: '\ec48';
}

/* '' */
.fa-firefox:before {
  content: '\ec49';
}

/* '' */
.fa-first-order-alt:before {
  content: '\ec4a';
}

/* '' */
.fa-first-order:before {
  content: '\ec4b';
}

/* '' */
.fa-firstdraft:before {
  content: '\ec4c';
}

/* '' */
.fa-flickr:before {
  content: '\ec4d';
}

/* '' */
.fa-flipboard:before {
  content: '\ec4e';
}

/* '' */
.fa-fly:before {
  content: '\ec4f';
}

/* '' */
.fa-font-awesome-alt:before {
  content: '\ec50';
}

/* '' */
.fa-font-awesome-flag:before {
  content: '\ec51';
}

/* '' */
.fa-font-awesome-logo-full-1:before {
  content: '\ec52';
}

/* '' */
.fa-font-awesome:before {
  content: '\ec53';
}

/* '' */
.fa-fonticons-fi:before {
  content: '\ec54';
}

/* '' */
.fa-fonticons:before {
  content: '\ec55';
}

/* '' */
.fa-fort-awesome-alt:before {
  content: '\ec56';
}

/* '' */
.fa-fort-awesome:before {
  content: '\ec57';
}

/* '' */
.fa-forumbee:before {
  content: '\ec58';
}

/* '' */
.fa-foursquare:before {
  content: '\ec59';
}

/* '' */
.fa-free-code-camp:before {
  content: '\ec5a';
}

/* '' */
.fa-freebsd:before {
  content: '\ec5b';
}

/* '' */
.fa-fulcrum:before {
  content: '\ec5c';
}

/* '' */
.fa-galactic-republic:before {
  content: '\ec5d';
}

/* '' */
.fa-galactic-senate:before {
  content: '\ec5e';
}

/* '' */
.fa-get-pocket:before {
  content: '\ec5f';
}

/* '' */
.fa-gg-circle:before {
  content: '\ec60';
}

/* '' */
.fa-gg:before {
  content: '\ec61';
}

/* '' */
.fa-git-square:before {
  content: '\ec62';
}

/* '' */
.fa-git:before {
  content: '\ec63';
}

/* '' */
.fa-github-alt:before {
  content: '\ec64';
}

/* '' */
.fa-github-square:before {
  content: '\ec65';
}

/* '' */
.fa-github:before {
  content: '\ec66';
}

/* '' */
.fa-gitkraken:before {
  content: '\ec67';
}

/* '' */
.fa-gitlab:before {
  content: '\ec68';
}

/* '' */
.fa-gitter:before {
  content: '\ec69';
}

/* '' */
.fa-glide-g:before {
  content: '\ec6a';
}

/* '' */
.fa-glide:before {
  content: '\ec6b';
}

/* '' */
.fa-gofore:before {
  content: '\ec6c';
}

/* '' */
.fa-goodreads-g:before {
  content: '\ec6d';
}

/* '' */
.fa-goodreads:before {
  content: '\ec6e';
}

/* '' */
.fa-google-drive:before {
  content: '\ec6f';
}

/* '' */
.fa-google-play:before {
  content: '\ec70';
}

/* '' */
.fa-google-plus-g:before {
  content: '\ec71';
}

/* '' */
.fa-google-plus-square:before {
  content: '\ec72';
}

/* '' */
.fa-google-plus:before {
  content: '\ec73';
}

/* '' */
.fa-google-wallet:before {
  content: '\ec74';
}

/* '' */
.fa-google:before {
  content: '\ec75';
}

/* '' */
.fa-gratipay:before {
  content: '\ec76';
}

/* '' */
.fa-grav:before {
  content: '\ec77';
}

/* '' */
.fa-gripfire:before {
  content: '\ec78';
}

/* '' */
.fa-grunt:before {
  content: '\ec79';
}

/* '' */
.fa-gulp:before {
  content: '\ec7a';
}

/* '' */
.fa-hacker-news-square:before {
  content: '\ec7b';
}

/* '' */
.fa-hacker-news:before {
  content: '\ec7c';
}

/* '' */
.fa-hips:before {
  content: '\ec7d';
}

/* '' */
.fa-hire-a-helper:before {
  content: '\ec7e';
}

/* '' */
.fa-hooli:before {
  content: '\ec7f';
}

/* '' */
.fa-hornbill:before {
  content: '\ec80';
}

/* '' */
.fa-hotjar:before {
  content: '\ec81';
}

/* '' */
.fa-houzz:before {
  content: '\ec82';
}

/* '' */
.fa-html5:before {
  content: '\ec83';
}

/* '' */
.fa-hubspot:before {
  content: '\ec84';
}

/* '' */
.fa-imdb:before {
  content: '\ec85';
}

/* '' */
.fa-instagram:before {
  content: '\ec86';
}

/* '' */
.fa-internet-explorer:before {
  content: '\ec87';
}

/* '' */
.fa-ioxhost:before {
  content: '\ec88';
}

/* '' */
.fa-itunes-note:before {
  content: '\ec89';
}

/* '' */
.fa-itunes:before {
  content: '\ec8a';
}

/* '' */
.fa-java:before {
  content: '\ec8b';
}

/* '' */
.fa-jedi-order:before {
  content: '\ec8c';
}

/* '' */
.fa-jenkins:before {
  content: '\ec8d';
}

/* '' */
.fa-joget:before {
  content: '\ec8e';
}

/* '' */
.fa-joomla:before {
  content: '\ec8f';
}

/* '' */
.fa-js-square:before {
  content: '\ec90';
}

/* '' */
.fa-js:before {
  content: '\ec91';
}

/* '' */
.fa-jsfiddle:before {
  content: '\ec92';
}

/* '' */
.fa-keybase:before {
  content: '\ec93';
}

/* '' */
.fa-keycdn:before {
  content: '\ec94';
}

/* '' */
.fa-kickstarter-k:before {
  content: '\ec95';
}

/* '' */
.fa-kickstarter:before {
  content: '\ec96';
}

/* '' */
.fa-korvue:before {
  content: '\ec97';
}

/* '' */
.fa-laravel:before {
  content: '\ec98';
}

/* '' */
.fa-lastfm-square:before {
  content: '\ec99';
}

/* '' */
.fa-lastfm:before {
  content: '\ec9a';
}

/* '' */
.fa-leanpub:before {
  content: '\ec9b';
}

/* '' */
.fa-less:before {
  content: '\ec9c';
}

/* '' */
.fa-line:before {
  content: '\ec9d';
}

/* '' */
.fa-linkedin-in:before {
  content: '\ec9e';
}

/* '' */
.fa-linkedin:before {
  content: '\ec9f';
}

/* '' */
.fa-linode:before {
  content: '\eca0';
}

/* '' */
.fa-linux:before {
  content: '\eca1';
}

/* '' */
.fa-lyft:before {
  content: '\eca2';
}

/* '' */
.fa-magento:before {
  content: '\eca3';
}

/* '' */
.fa-mailchimp:before {
  content: '\eca4';
}

/* '' */
.fa-mandalorian:before {
  content: '\eca5';
}

/* '' */
.fa-mastodon:before {
  content: '\eca6';
}

/* '' */
.fa-maxcdn:before {
  content: '\eca7';
}

/* '' */
.fa-medapps:before {
  content: '\eca8';
}

/* '' */
.fa-medium-m:before {
  content: '\eca9';
}

/* '' */
.fa-medium:before {
  content: '\ecaa';
}

/* '' */
.fa-medrt:before {
  content: '\ecab';
}

/* '' */
.fa-meetup:before {
  content: '\ecac';
}

/* '' */
.fa-megaport:before {
  content: '\ecad';
}

/* '' */
.fa-microsoft:before {
  content: '\ecae';
}

/* '' */
.fa-mix:before {
  content: '\ecaf';
}

/* '' */
.fa-mixcloud:before {
  content: '\ecb0';
}

/* '' */
.fa-mizuni:before {
  content: '\ecb1';
}

/* '' */
.fa-modx:before {
  content: '\ecb2';
}

/* '' */
.fa-monero:before {
  content: '\ecb3';
}

/* '' */
.fa-napster:before {
  content: '\ecb4';
}

/* '' */
.fa-nimblr:before {
  content: '\ecb5';
}

/* '' */
.fa-nintendo-switch:before {
  content: '\ecb6';
}

/* '' */
.fa-node-js:before {
  content: '\ecb7';
}

/* '' */
.fa-node:before {
  content: '\ecb8';
}

/* '' */
.fa-npm:before {
  content: '\ecb9';
}

/* '' */
.fa-ns8:before {
  content: '\ecba';
}

/* '' */
.fa-nutritionix:before {
  content: '\ecbb';
}

/* '' */
.fa-odnoklassniki-square:before {
  content: '\ecbc';
}

/* '' */
.fa-odnoklassniki:before {
  content: '\ecbd';
}

/* '' */
.fa-old-republic:before {
  content: '\ecbe';
}

/* '' */
.fa-opencart:before {
  content: '\ecbf';
}

/* '' */
.fa-openid:before {
  content: '\ecc0';
}

/* '' */
.fa-opera:before {
  content: '\ecc1';
}

/* '' */
.fa-optin-monster:before {
  content: '\ecc2';
}

/* '' */
.fa-osi:before {
  content: '\ecc3';
}

/* '' */
.fa-page4:before {
  content: '\ecc4';
}

/* '' */
.fa-pagelines:before {
  content: '\ecc5';
}

/* '' */
.fa-palfed:before {
  content: '\ecc6';
}

/* '' */
.fa-patreon:before {
  content: '\ecc7';
}

/* '' */
.fa-paypal:before {
  content: '\ecc8';
}

/* '' */
.fa-periscope:before {
  content: '\ecc9';
}

/* '' */
.fa-phabricator:before {
  content: '\ecca';
}

/* '' */
.fa-phoenix-framework:before {
  content: '\eccb';
}

/* '' */
.fa-phoenix-squadron:before {
  content: '\eccc';
}

/* '' */
.fa-php:before {
  content: '\eccd';
}

/* '' */
.fa-pied-piper-alt:before {
  content: '\ecce';
}

/* '' */
.fa-pied-piper-hat:before {
  content: '\eccf';
}

/* '' */
.fa-pied-piper-pp:before {
  content: '\ecd0';
}

/* '' */
.fa-pied-piper:before {
  content: '\ecd1';
}

/* '' */
.fa-pinterest-p:before {
  content: '\ecd2';
}

/* '' */
.fa-pinterest-square:before {
  content: '\ecd3';
}

/* '' */
.fa-pinterest:before {
  content: '\ecd4';
}

/* '' */
.fa-playstation:before {
  content: '\ecd5';
}

/* '' */
.fa-product-hunt:before {
  content: '\ecd6';
}

/* '' */
.fa-pushed:before {
  content: '\ecd7';
}

/* '' */
.fa-python:before {
  content: '\ecd8';
}

/* '' */
.fa-qq:before {
  content: '\ecd9';
}

/* '' */
.fa-quinscape:before {
  content: '\ecda';
}

/* '' */
.fa-quora:before {
  content: '\ecdb';
}

/* '' */
.fa-r-project:before {
  content: '\ecdc';
}

/* '' */
.fa-ravelry:before {
  content: '\ecdd';
}

/* '' */
.fa-react:before {
  content: '\ecde';
}

/* '' */
.fa-readme:before {
  content: '\ecdf';
}

/* '' */
.fa-rebel:before {
  content: '\ece0';
}

/* '' */
.fa-red-river:before {
  content: '\ece1';
}

/* '' */
.fa-reddit-alien:before {
  content: '\ece2';
}

/* '' */
.fa-reddit-square:before {
  content: '\ece3';
}

/* '' */
.fa-reddit:before {
  content: '\ece4';
}

/* '' */
.fa-rendact:before {
  content: '\ece5';
}

/* '' */
.fa-renren:before {
  content: '\ece6';
}

/* '' */
.fa-replyd:before {
  content: '\ece7';
}

/* '' */
.fa-researchgate:before {
  content: '\ece8';
}

/* '' */
.fa-resolving:before {
  content: '\ece9';
}

/* '' */
.fa-rev:before {
  content: '\ecea';
}

/* '' */
.fa-rocketchat:before {
  content: '\eceb';
}

/* '' */
.fa-rockrms:before {
  content: '\ecec';
}

/* '' */
.fa-safari:before {
  content: '\eced';
}

/* '' */
.fa-sass:before {
  content: '\ecee';
}

/* '' */
.fa-schlix:before {
  content: '\ecef';
}

/* '' */
.fa-scribd:before {
  content: '\ecf0';
}

/* '' */
.fa-searchengin:before {
  content: '\ecf1';
}

/* '' */
.fa-sellcast:before {
  content: '\ecf2';
}

/* '' */
.fa-sellsy:before {
  content: '\ecf3';
}

/* '' */
.fa-servicestack:before {
  content: '\ecf4';
}

/* '' */
.fa-shirtsinbulk:before {
  content: '\ecf5';
}

/* '' */
.fa-shopware:before {
  content: '\ecf6';
}

/* '' */
.fa-simplybuilt:before {
  content: '\ecf7';
}

/* '' */
.fa-sistrix:before {
  content: '\ecf8';
}

/* '' */
.fa-sith:before {
  content: '\ecf9';
}

/* '' */
.fa-skyatlas:before {
  content: '\ecfa';
}

/* '' */
.fa-skype:before {
  content: '\ecfb';
}

/* '' */
.fa-slack-hash:before {
  content: '\ecfc';
}

/* '' */
.fa-slack:before {
  content: '\ecfd';
}

/* '' */
.fa-slideshare:before {
  content: '\ecfe';
}

/* '' */
.fa-snapchat-ghost:before {
  content: '\ecff';
}

/* '' */
.fa-snapchat-square:before {
  content: '\ed00';
}

/* '' */
.fa-snapchat:before {
  content: '\ed01';
}

/* '' */
.fa-soundcloud:before {
  content: '\ed02';
}

/* '' */
.fa-speakap:before {
  content: '\ed03';
}

/* '' */
.fa-spotify:before {
  content: '\ed04';
}

/* '' */
.fa-squarespace:before {
  content: '\ed05';
}

/* '' */
.fa-stack-exchange:before {
  content: '\ed06';
}

/* '' */
.fa-stack-overflow:before {
  content: '\ed07';
}

/* '' */
.fa-staylinked:before {
  content: '\ed08';
}

/* '' */
.fa-steam-square:before {
  content: '\ed09';
}

/* '' */
.fa-steam-symbol:before {
  content: '\ed0a';
}

/* '' */
.fa-steam:before {
  content: '\ed0b';
}

/* '' */
.fa-sticker-mule:before {
  content: '\ed0c';
}

/* '' */
.fa-strava:before {
  content: '\ed0d';
}

/* '' */
.fa-stripe-s:before {
  content: '\ed0e';
}

/* '' */
.fa-stripe:before {
  content: '\ed0f';
}

/* '' */
.fa-studiovinari:before {
  content: '\ed10';
}

/* '' */
.fa-stumbleupon-circle:before {
  content: '\ed11';
}

/* '' */
.fa-stumbleupon:before {
  content: '\ed12';
}

/* '' */
.fa-superpowers:before {
  content: '\ed13';
}

/* '' */
.fa-supple:before {
  content: '\ed14';
}

/* '' */
.fa-teamspeak:before {
  content: '\ed15';
}

/* '' */
.fa-telegram-plane:before {
  content: '\ed16';
}

/* '' */
.fa-telegram:before {
  content: '\ed17';
}

/* '' */
.fa-tencent-weibo:before {
  content: '\ed18';
}

/* '' */
.fa-themeco:before {
  content: '\ed19';
}

/* '' */
.fa-themeisle:before {
  content: '\ed1a';
}

/* '' */
.fa-trade-federation:before {
  content: '\ed1b';
}

/* '' */
.fa-trello:before {
  content: '\ed1c';
}

/* '' */
.fa-tripadvisor:before {
  content: '\ed1d';
}

/* '' */
.fa-tumblr-square:before {
  content: '\ed1e';
}

/* '' */
.fa-tumblr:before {
  content: '\ed1f';
}

/* '' */
.fa-twitch:before {
  content: '\ed20';
}

/* '' */
.fa-twitter-square:before {
  content: '\ed21';
}

/* '' */
.fa-twitter:before {
  content: '\ed22';
}

/* '' */
.fa-typo3:before {
  content: '\ed23';
}

/* '' */
.fa-uber:before {
  content: '\ed24';
}

/* '' */
.fa-uikit:before {
  content: '\ed25';
}

/* '' */
.fa-uniregistry:before {
  content: '\ed26';
}

/* '' */
.fa-untappd:before {
  content: '\ed27';
}

/* '' */
.fa-usb:before {
  content: '\ed28';
}

/* '' */
.fa-ussunnah:before {
  content: '\ed29';
}

/* '' */
.fa-vaadin:before {
  content: '\ed2a';
}

/* '' */
.fa-viacoin:before {
  content: '\ed2b';
}

/* '' */
.fa-viadeo-square:before {
  content: '\ed2c';
}

/* '' */
.fa-viadeo:before {
  content: '\ed2d';
}

/* '' */
.fa-viber:before {
  content: '\ed2e';
}

/* '' */
.fa-vimeo-square:before {
  content: '\ed2f';
}

/* '' */
.fa-vimeo-v:before {
  content: '\ed30';
}

/* '' */
.fa-vimeo:before {
  content: '\ed31';
}

/* '' */
.fa-vine:before {
  content: '\ed32';
}

/* '' */
.fa-vk:before {
  content: '\ed33';
}

/* '' */
.fa-vnv:before {
  content: '\ed34';
}

/* '' */
.fa-vuejs:before {
  content: '\ed35';
}

/* '' */
.fa-weebly:before {
  content: '\ed36';
}

/* '' */
.fa-weibo:before {
  content: '\ed37';
}

/* '' */
.fa-weixin:before {
  content: '\ed38';
}

/* '' */
.fa-whatsapp-square:before {
  content: '\ed39';
}

/* '' */
.fa-whatsapp:before {
  content: '\ed3a';
}

/* '' */
.fa-whmcs:before {
  content: '\ed3b';
}

/* '' */
.fa-wikipedia-w:before {
  content: '\ed3c';
}

/* '' */
.fa-windows:before {
  content: '\ed3d';
}

/* '' */
.fa-wix:before {
  content: '\ed3e';
}

/* '' */
.fa-wolf-pack-battalion:before {
  content: '\ed3f';
}

/* '' */
.fa-wordpress-simple:before {
  content: '\ed40';
}

/* '' */
.fa-wordpress:before {
  content: '\ed41';
}

/* '' */
.fa-wpbeginner:before {
  content: '\ed42';
}

/* '' */
.fa-wpexplorer:before {
  content: '\ed43';
}

/* '' */
.fa-wpforms:before {
  content: '\ed44';
}

/* '' */
.fa-xbox:before {
  content: '\ed45';
}

/* '' */
.fa-xing-square:before {
  content: '\ed46';
}

/* '' */
.fa-xing:before {
  content: '\ed47';
}

/* '' */
.fa-y-combinator:before {
  content: '\ed48';
}

/* '' */
.fa-yahoo:before {
  content: '\ed49';
}

/* '' */
.fa-yandex-international:before {
  content: '\ed4a';
}

/* '' */
.fa-yandex:before {
  content: '\ed4b';
}

/* '' */
.fa-yelp:before {
  content: '\ed4c';
}

/* '' */
.fa-yoast:before {
  content: '\ed4d';
}

/* '' */
.fa-youtube-square:before {
  content: '\ed4e';
}

/* '' */
.fa-youtube:before {
  content: '\ed4f';
}

.fa-user-solid:before {
  content: '\ed50';
}

/* '' */

.fa-exit:before {
  content: '\ed51';
}

/* '' */
.fa-account-box:before {
  content: '\ed52';
}

/* '' */
.fa-assignment:before {
  content: '\ed53';
}

/* '' */
.fa-business:before {
  content: '\ed54';
}

/* '' */
.fa-format-paint:before {
  content: '\ed55';
}

/* '' */
.fa-formula:before {
  content: '\ed56';
}

/* '' */
.fa-history-alt:before {
  content: '\ed57';
}

/* '' */
.fa-subject:before {
  content: '\ed58';
}

/* '' */
.fa-view-compact:before {
  content: '\ed59';
}

/* '' */
.fa-plus-circle-solid:before {
  content: '\ed5a';
}

/* '' */
.fa-dashed-user:before {
  content: '\ed5b';
}

/* '' */
.fa-marker-tag:before {
  content: '\ed5c';
}

/* '' */

.fa-envelop-solid:before {
  content: '\ed66';
}

/* '' */
.fa-heart-solid:before {
  content: '\ed5e';
}

/* '' */
.fa-pencil-solid:before {
  content: '\ed5f';
}

/* '' */
.fa-ellipsis-solid:before {
  content: '\ed60';
}

/* '' */
.fa-trash-solid:before {
  content: '\ed61';
}

/* '' */
.fa-birthday-cake-solid:before {
  content: '\ed5d';
}

/* '' */
.fa-map-marker-alt-solid:before {
  content: '\ed63';
}

/* '' */
.fa-phone-alt-solid:before {
  content: '\ed64';
}

/* '' */
.fa-caret-circle-solid:before {
  content: '\ed65';
} /* '' */

.fa-save-solid:before {
  content: '\ed67';
} /* '' */
.fa-print-solid:before {
  content: '\ed62';
} /* '' */
