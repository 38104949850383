.react-custom-select {
  .react-custom-select__control {
    height: 32px;
    min-height: unset;
    border-color: $input-border-color;

    &.react-custom-select__control--is-focused {
      box-shadow: $input-focus-box-shadow;
      border-color: $primary;
    }
  }

  &.is-invalid {
    .react-custom-select__control {
      border-color: $danger;
      &.react-custom-select__control--is-focused {
        box-shadow: 0 0 0 $input-btn-focus-width rgba(230, 75, 75, 0.25);
      }
    }
  }

  .react-custom-select__indicator {
    padding: 5px;
    color: $primary;

    svg {
      width: 15px;
    }
  }

  .react-custom-select__indicator-separator {
    display: none;
  }

  .react-custom-select__single-value {
    color: $input-color;
  }
}

.was-validated .react-custom-select:invalid ~ .invalid-feedback,
.react-datepicker-wrapper ~ .invalid-feedback,
.react-custom-select.is-invalid ~ .invalid-feedback {
  display: block;
}
