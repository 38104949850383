.breadcrumb {
  font-family: $font-semi-bold;
}

.breadcrumb-item {
  a {
    color: $breadcrumb-active-color;
    &:hover {
      color: $breadcrumb-active-color;
    }
  }
}
