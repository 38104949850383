.wizard-bordero {
  margin-bottom: 35px;

  // Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
  .card {
    margin-bottom: 0;
  }
  // Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
  .card-body {
    padding: 1.25rem 15px;
    .row {
      margin-right: -15px;
      margin-left: -15px;
    }
  }
  .wizard {
    &.step-1 {
      .fixed-side-info {
        display: none;
      }
    }
    .body {
      min-height: calc(100vh - 260px);
      h1:first-child {
        padding-top: 15px;
        margin-bottom: 20px;
      }
    }
    &.visualizar-titulos {
      .body {
        min-height: calc(100vh - 180px);
      }
      .table {
        width: 99.5%;
      }
      .fixed-side {
        .title {
          margin-bottom: 10px;
        }
        .label {
          margin-bottom: 0.25rem;
        }
        .form-group {
          margin-bottom: 1rem;
        }
      }
    }
    .fixed-side-info {
      @include transitions(300ms, ease);
      display: block;
      // Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
      // -ms-flex: 0 0 259px;
      // flex: 0 0 259px;
    }
  }

  .card {
    // min-height: calc(100vh - 104px);
    &.nr-radius {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .card-body {
      overflow-x: hidden;
      padding-bottom: 0;
      transform: translateZ(0);
    }

    .title,
    .card-title {
      font-size: $h1-font-size;
      color: $gray-900;
      font-family: $font-light;
      margin-bottom: 25px;
    }
  }

  .upload-step {
    .body {
      // padding-left: 54px;
      padding-bottom: 70px;
      position: relative;
    }

    .dropzone {
      padding: 28px 0;
      margin-bottom: 10px;
    }

    fieldset {
      &.form-group {
        margin-bottom: 0;
      }
    }

    .info-icon {
      font-size: 20px;
      color: $blue;
      margin-right: 12px;
    }
    legend {
      font-family: $font-semi-bold;
      margin-bottom: 14px;
      color: #242b3b;
      font-size: 12px;
    }
    .custom-label {
      font-size: $font-size-base;
      font-family: $font-semi-bold;
      color: $gray-900;
      margin-top: 1px;
      margin-bottom: 14px;
    }
    .company-name {
      margin-bottom: 40px;
      font-weight: normal;
      text-transform: none;
      font-size: 12px;
      color: #606268;
    }
  }

  .selection-step {
    transition: all 0.2s linear;
    .fixed-side {
      @include transitions(300ms, ease);
      animation: slideInRight 0.5s;
      top: 0;
      right: 0;
      max-width: 260px;
      height: 100%;
      border-left: solid 1px #e5e5e5;
      position: fixed;
      background-color: #f6f6f6;
      // padding: 25px $custom-control-spacer-x 0 $custom-control-spacer-x;
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .bottom {
        margin-top: auto;
        margin-left: auto;
      }
    }
    .col-absolute {
      position: absolute;
      .d-flex {
        padding: 0 25px !important;
        .selecao-itens {
          background-color: #fff;
        }
      }
    }

    table {
      thead {
        tr {
          th {
            &[data-row-selection] {
              width: 46px;
            }
          }
        }
      }

      td {
        .expand-icon {
          font-size: 18px;
          cursor: pointer;
          color: $primary;
        }
      }

      .row-expansion-style {
        padding-top: 14px;
        padding-bottom: 14px;
      }
    }

    // Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
    // .col-selection-step {
    //   width: calc(100% - 260px);
    // }

    &.collapse {
      .col-selection-step {
        width: 100%;
      }
    }

    .grid-scrollable-content {
      // max-height: calc(100vh - 350px);
      // overflow-x: hidden;
      // overflow-y: auto;
      .scrollable-content {
        max-height: calc(
          100vh - 375px
        ); // Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
        overflow: auto;
      }
    }

    .grid-scrollable-content.haveMessage {
      .scrollable-content {
        max-height: calc(100vh - 445px);
        overflow: auto;
      }
    }

    .expanded-container {
      padding-left: 50px;
    }

    .expanded-container,
    .info {
      .form-control-static {
        margin-bottom: 0;
        font-size: $font-size-base;
        color: #606268;
      }
    }

    .expanded-container {
      label {
        font-size: 14px;
      }
    }

    .info {
      .form-group {
        margin-bottom: 1.5rem;
      }
      label {
        font-size: $font-size-base;
      }
      .total-price {
        font-family: $font-bold;
        font-size: 22px;
        color: $primary;
      }
    }
  }

  .btn {
    vertical-align: unset;
    &.btn-minimal {
      font-weight: $font-weight-bold;
      .icon-times {
        &:hover {
          opacity: 1;
        }
        opacity: 0.5;
        font-size: 18px;
        color: $gray-700;
      }
      min-width: 0 !important;
      height: 0 !important;
    }
  }

  .upload-step,
  .selection-step {
    .btn {
      &:not(.rounded-circle) {
        min-width: 120px;
        height: 35px;
        display: inline-flex;
        padding: 0 8px;
        align-items: center;
        justify-content: center;
        &:hover {
          svg {
            fill: currentColor;
          }
        }

        .svg-icon {
          margin-right: 15px;
          margin-top: -3px;
        }

        svg {
          height: 12px;
          fill: currentColor;
          width: unset;
        }
      }
    }
    .fixed-side {
      .header {
        padding: 20px 14px 24px;
        border-bottom: 1px solid #e5e5e5;
        height: 78px;
        .title {
          color: $gray-900;
          font-family: $font-light;
          font-size: $h1-font-size;
          margin-bottom: 0;
          margin-top: 5px;
        }
      }
    }
  }

  .upload-step {
    &.pending {
      .footer {
        .btn-wrapper {
          span {
            &:not(:first-child) {
              &:nth-child(2) {
                margin-left: auto;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.modalInclusaoTitulo .title {
  color: $gray-900;
}

.include-link,
.clear-fields {
  font-size: $font-size-base;
  font-family: $font-semi-bold;
  text-decoration: underline;
  margin-left: auto;

  &:hover,
  &:active,
  &:focus,
  & {
    background-color: transparent !important;
    color: $primary !important;
  }
  border: 0;
}

.incluir-titulos {
  font-size: $font-size-base;
  font-family: $font-semi-bold;
  text-decoration: underline;
  border: 1px solid;
  margin-right: 10px;
  margin-left: auto;
}

.grid-buttons-selecao {
  @include media-breakpoint-up(lg) {
    display: flex !important;
  }
  @include media-breakpoint-down(lg) {
    display: block !important;
    button {
      margin-top: 5px;
    }
  }
}

.btn-upload-xml {
  margin-right: 10px;
}

.modalDuplicata {
  .file-wrapper {
    .font-bold {
      font-weight: bold;
    }
    .file-obs {
      padding-right: 20px;
      padding-left: 20px;
    }
    .my-auto {
      word-wrap: break-word;
    }
    .col-md-1 {
      padding: 0;

      .btn {
        padding: 0;
      }
    }
  }
}

.modalImportarXML {
  .file-wrapper {
    .font-bold {
      font-weight: bold;
    }
    .file-obs {
      padding-right: 20px;
      padding-left: 20px;
    }
    .my-auto {
      word-wrap: break-word;
    }
    .col-md-1 {
      padding: 0;

      .btn {
        padding: 0;
      }
    }
  }
}

.modalGiroRapido {
  .modal-body {
    display: flex;
    justify-content: space-between;
    .form-group {
      width: calc(50% - 15px);
      display: flex;
      flex-direction: column;
      .react-datepicker__input-container {
        width: 100%;
      }
    }
  }
}

.modalXml {
  .file-wrapper {
    .font-bold {
      font-weight: bold;
    }
    .file-obs {
      padding-right: 20px;
      padding-left: 20px;
    }
    .my-auto {
      word-wrap: break-word;
    }
    .col-md-1 {
      padding: 0;

      .btn {
        padding: 0;
      }
    }
  }
}

.btn-outline-warning:hover {
  color: #fff;
}

.btn-outline-danger:focus {
  color: #fff;
  background-color: #e64b4b;
  border-color: #e64b4b;
}

.btn-outline-warning:focus {
  color: #fff;
  background-color: #f1bc57;
  border-color: #f1bc57;
}

.label-pointer-none {
  label {
    pointer-events: none;
  }
}

.card-ajuste-ndoc {
  border: 1.5px solid;
  border-color: #dddddd;
  border-radius: 11px;
}

.select-plataforma-relatorios {
  .select-plataforma-relatorios__menu {
    max-height: 200px !important;
    .select-plataforma-relatorios__menu-list {
      max-height: 200px !important;
    }
  }
}
