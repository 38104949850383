@mixin empty-table-message {
  padding-left: 58px;
  .title {
    margin-bottom: 10px;
    font-family: $font-bold;
    font-size: 12px;
  }
}

.wizard-bordero {
  .empty-table-message {
    @include empty-table-message;
  }
}
.empty-table-message {
  @include empty-table-message;
}

.table {
  thead th {
    border-width: 1px;
    background-color: #f6f6f6;
    font-size: 12px;
    color: #4a4a4a;
    font-family: $font-semi-bold;
    border-color: #e5e5e5;
    vertical-align: baseline;
  }

  th,
  td {
    vertical-align: middle;
  }

  .badge-light {
    border-radius: 4px;
    border: solid 1px #e5e5e5;
    background-color: #f6f6f6;
    padding: 4px 8px;
    font-family: $font-semi-bold;
    font-size: 12px;
    font-weight: 600;
    margin-left: 12px;
  }

  tfoot {
    td {
      padding-right: 0;
      padding-top: 20px;
      label {
        font-family: $font-regular;
        font-size: 12px;
        color: #606268;
      }
    }
  }
}

.table-layout-fixed {
  table-layout: fixed !important;
}

.table-layout-auto {
  table-layout: auto !important;
}
