@import 'mixins/buttons.scss';
@import 'mixins/vendor-prefixes.scss';
@import 'mixins/opacity.scss';

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
