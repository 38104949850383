.loadingWrapper {
  position: relative;
  .spinner-border {
    position: absolute;
    top: 38px;
    right: 8px;

    width: 0.6rem;
    height: 0.6rem;
    border-color: $input-border-color transparent $input-border-color $input-border-color;
  }
}
