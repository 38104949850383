.card-stats {
  height: 314px;
  // &.card {
  //   .card-body {
  //     padding: 0;
  //   }
  // }
  &.loaded .stats {
    &.danger-gradient {
      background-image: radial-gradient(circle at 52% 46%, #d58b8b, #e36161);
      border-radius: $border-radius $border-radius 0 0;
    }
    &.orange-gradient {
      background-image: radial-gradient(circle at 49% 53%, #efd29b, #d5a03c);
      border-radius: 0 0 $border-radius $border-radius;
    }

    .stats-col {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 157px;
    }
  }
  .stats {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 157px;
    text-decoration: none !important;

    .stats-value,
    .stats-title {
      font-family: $font-semi-bold;
      color: $white;
    }

    .stats-value {
      font-size: 88px;
      margin-right: 22.8px;
      line-height: 0.8;
      margin-top: -4px;
    }

    .stats-title {
      text-transform: uppercase;
      width: 86px;
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-only(lg) {
    height: 157px;
    .stats-col {
      &:first-child {
        padding-right: 0;
      }
      &:last-of-type {
        padding-left: 0;
      }
    }
    .stats {
      // Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
      &.danger-gradient {
        border-radius: $border-radius 0 0 $border-radius !important;
      }
      &.orange-gradient {
        border-radius: 0 $border-radius $border-radius 0 !important;
      }
    }
  }
}
