html {
  body {
    .main-container {
      padding-right: 27px !important;
    }
    .alert-trocar-empresa {
      .swal-title {
        font-family: 'IBM Plex Sans Bold' !important;
        font-size: 18px;
        color: #1a284d;
        border: 0;
      }
      .swal-text {
        font-size: 16px;
        color: #1a284d;
        padding: 15px;
        padding-top: 5px;
      }
      .swal-footer {
        font-family: 'IBM Plex Sans Semi-Bold' !important;
        background-color: white;
        .swal-button--cancel {
          color: #1a284d;
          background-color: transparent;
          font-size: 16px;
          font-weight: 500;
        }
        .swal-button--confirm {
          background-color: #1a284d;
          font-size: 16px;
          font-weight: 500;
        }
        .swal-button--confirm:hover {
          background-color: #1a284d;
        }
        .swal-button:focus {
          outline: none;
          box-shadow: none !important;
        }
      }
    }
    #root {
      .wrapper {
        .div-fundo-escuro {
          background-color: #2121218f;
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 9;
          animation: fadeIn 0.1s;
          &.aumenta-z-index {
            z-index: 999;
          }
        }
        .img-logo-coll-onboarding {
          width: 35px;
          height: 45px;
          margin-top: 5px;
        }
        .header-onboarding-usuario-logado {
          position: absolute;
          z-index: 99;
          // background-color: #1a284d;
          top: 0;
          right: 0;
          margin: 8px 18px 0 0;
          background-color: #ffffff;
          border-radius: 4px;
        }

        .fixed-usuario-logado {
          position: fixed;
          right: 0;
          top: 0;
          z-index: 900;
          margin: 8px 18px 0 0;
          background-color: #ffffff;
          border-radius: 4px;
        }
        .box-usuario-logado {
          .box-usuario {
            cursor: pointer;
            user-select: none;
            display: flex;
            align-items: center;
            width: 300px;
            border: 1px solid #ededf7;
            background-color: #ffffff;
            border-radius: 4px;
            float: right;
            color: #333333;
            font-size: 14px;
            position: sticky;
            z-index: 900;
            &.focus {
              z-index: 999;
            }
            padding: 5px 0;
            justify-content: space-between;
            flex-wrap: nowrap;

            .box-usuario-empresa {
              width: 210px;
            }
            .icone-arrow-down {
              padding-right: 8px;
            }
          }
          .responsive {
            width: 245px;
            .box-responive {
              width: 155px;
            }
          }
          .imagem-usuario-logado {
            width: 35px;
            height: 35px;
            margin-left: 8px;
            margin-right: 8px;
            margin-top: 0px;
            margin-bottom: 0px;
            border: 1px solid #ededf7;
            border-radius: 50%;
            display: flex;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: center;
            font-size: 1rem;
            font-weight: bold;
          }
          .nome-usuario-logado {
            font-weight: bold;
            height: 17px;
          }
          .nome-empresa-selecionada {
            color: #97989e;
            min-width: 175px;
            white-space: nowrap !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
          }
          .box-clientes {
            width: 260px;
            border: 1px solid white;
            border-radius: 4px;
            color: #5ad4ff;
            font-size: 14px;
            z-index: 999;
            position: absolute;
            top: 55px;
            right: 18px;
            background-color: white;
            animation: fadeIn 0.5s;
            &.responsive {
              width: 245px;
              .input-pesquisa-cliente {
                width: 215px !important;
              }
            }
            .input-pesquisa-cliente {
              width: 235px;
              margin-top: 10px;
              margin-left: 10px;
              margin-right: 5px;
              margin-bottom: 10px;
              height: 30px;
              background-color: #f5f5f5;
              outline: 0;
            }
            .lista-clientes {
              color: black;
              margin-left: 10px;
              font-size: 12px;
              min-width: 245px;
              max-width: 245px;
              max-height: 230px;
              overflow-y: overlay;
              overflow-x: hidden;
              &::-webkit-scrollbar-track {
                background-color: #f4f4f4;
              }

              &::-webkit-scrollbar {
                height: 6px;
                width: 10px;
              }

              &::-webkit-scrollbar-thumb {
                background: #a7a7a7;
                border-radius: 8px;
              }

              button {
                justify-content: left;
                color: black !important;
                min-width: 235px;
                max-width: 235px;
                white-space: nowrap;
              }
              button:hover {
                background-color: #b3e5fc;
              }

              .spin-rotation {
                left: 42% !important;
              }
            }
            .alerta-total-empresas {
              padding: 10px 15px;
              font-size: 12px;
              color: #1a284d;
              font-weight: bold;
              cursor: default;
            }
            .link-cadastrar-empresa {
              font-weight: 500;
              color: #5ad4ff;
              border-top: #e0e0e0 1px solid;
              border-bottom: #e0e0e0 1px solid;
              .link-nova-empresa {
                margin: 15px 10px;
              }
            }
            .link-todas-empresas {
              padding: 8px 10px 10px 10px;

              button {
                font-weight: bold;
                color: #333 !important;
                justify-content: left;
                min-width: 235px;
                max-width: 235px;
                white-space: nowrap;
                text-transform: unset;
              }
              button:hover {
                background-color: #b3e5fc;
              }
              button:disabled {
                color: #747474 !important;
              }
            }
            .link-encerrar-sessao {
              margin: 15px 10px;
              .link-delogar {
                text-decoration: underline;
              }
            }
            button:disabled {
              cursor: default;
            }
          }
        }
        .main-panel {
          .content {
            padding-top: $content-padding-top - 50;
          }

          .cliente-title {
            font-size: 1.2rem;
            color: #777;
          }

          .header-principal {
            background-color: #ffffff;
            height: 68px;
            position: fixed;
            width: 100%;
            top: 0;
            z-index: 8;
            box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.02), 0px 4px 5px 0px rgba(0, 0, 0, 0.02),
              0px 1px 10px 0px rgba(0, 0, 0, 0.1);
          }

          .content-body {
            .body {
              margin-bottom: 15px;
            }
          }

          .fixed-side-info {
            padding: 0;
            .infoBordero {
              height: calc(100vh - 180px);
              width: 100%;
            }
            .infoInstrucoes {
              height: calc(100vh - 220px);
              width: 100%;
            }
          }

          .row-content {
            margin-right: -13px;
            margin-left: -13px;
          }

          main.instrucoes:not(.instrucoes-grid) {
            .fixed-side {
              padding: 20px;
            }
          }

          main.relatorio,
          main.retorno,
          main.visualizar-titulo {
            .fixed-side {
              margin: -1.25rem 0 0 0.5rem;
            }
          }

          @include media-breakpoint-down(md) {
            .fixed-side-info {
              position: fixed;
            }

            main.borderos {
              .fixed-side-info {
                margin-left: -5px;
              }
            }

            main:not(.collapsed) {
              .content-body {
                margin-top: 610px;
              }
              &.wizard-bordero .content-body {
                margin-top: 620px;
              }
            }

            main.relatorio,
            main.retorno {
              .fixed-side {
                margin: -1.25rem 0 0 -0.8rem;
              }
            }
            main.visualizar-titulo {
              .fixed-side {
                margin: -1.25rem 0 0 -0.2rem;
              }
            }
          }

          @include media-breakpoint-up(lg) {
            main.wizard-bordero:not(.visualizar-titulo) {
              .fixed-side {
                margin-top: -100px;
                height: calc(100% + 100px) !important;
              }
            }

            main.instrucoes:not(.instrucoes-grid) {
              .fixed-side {
                margin-top: -100px;
                height: calc(100% + 106px) !important;
                padding: 20px;
              }
            }
          }

          .fixed-side {
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
            -webkit-animation: slideInRight 0.5s;
            animation: slideInRight 0.5s;
            top: unset;
            right: unset;
            max-width: unset;
            height: 100%;
            border-left: solid 1px #e5e5e5;
            position: unset;
            background-color: #f6f6f6;
            width: 100%;
            display: block;
            flex-direction: column;
            padding: 0 7px;

            .body {
              display: block;
              flex-direction: unset;
              max-height: unset;
              overflow-y: auto;
              overflow-x: hidden;
              position: unset;
              height: calc(100vh - 246px);
            }

            .bottom {
              position: unset;
            }
          }
        }
      }

      .btn-icon-changes {
        font-size: 2.2rem;
        padding: 0;
        margin: 0;

        .icon-default {
          opacity: 1;
          color: white;
          margin-top: 10px;
        }

        .icon-hover {
          opacity: 0;
        }

        .fa {
          transition: all 0.3s ease 0s;
          position: absolute;
          top: 3.5px;
          left: 0;
        }
      }

      .btn-icon-changes:hover {
        .icon-default {
          transform: rotate(180deg) scale(0.5);
          opacity: 0;
        }

        .icon-hover {
          transform: rotate(180deg) scale(1);
          opacity: 1;
        }
      }

      .btn:focus,
      .btn.focus {
        box-shadow: 0 0 4px 1px #acacac59;
      }
      .wizard-bordero {
        .selection-step {
          .col-absolute {
            position: unset;
          }
        }
      }
    }

    /* FONTES SIZES */
    .font-65 {
      font-size: 6.5rem;
      line-height: 1.2;
    }
    .font-625 {
      font-size: 6.25rem;
      line-height: 1.2;
    }
    .font-6 {
      font-size: 6rem;
      line-height: 1.2;
    }
    .font-575 {
      font-size: 5.75rem;
      line-height: 1.2;
    }
    .font-55 {
      font-size: 5.5rem;
      line-height: 1.2;
    }
    .font-525 {
      font-size: 5.25rem;
      line-height: 1.2;
    }
    .font-5 {
      font-size: 5rem;
      line-height: 1.2;
    }
    .font-475 {
      font-size: 4.75rem;
      line-height: 1.2;
    }
    .font-45 {
      font-size: 4.5rem;
      line-height: 1.2;
    }
    .font-425 {
      font-size: 4.25rem;
      line-height: 1.2;
    }
    .font-4 {
      font-size: 4rem;
      line-height: 1.2;
    }
    .font-375 {
      font-size: 3.75rem;
      line-height: 1.2;
    }
    .font-35 {
      font-size: 3.5rem;
      line-height: 1.2;
    }
    .font-325 {
      font-size: 3.25rem;
      line-height: 1.2;
    }
    .font-3 {
      font-size: 3rem;
      line-height: 1.2;
    }
    .font-275 {
      font-size: 2.75rem;
      line-height: 1.2;
    }
    .font-25 {
      font-size: 2.5rem;
      line-height: 1.2;
    }
    .font-225 {
      font-size: 2.25rem;
      line-height: 1.2;
    }
    .font-2 {
      font-size: 2rem;
      line-height: 1.2;
    }
    .font-175 {
      font-size: 1.75rem;
      line-height: 1.2;
    }
    .font-15 {
      font-size: 1.5rem;
      line-height: 1.2;
    }
    .font-125 {
      font-size: 1.25rem;
      line-height: 1.2;
    }
    .font-1 {
      font-size: 1rem;
      line-height: 1.2;
    }
    .font-075 {
      font-size: 0.75rem;
      line-height: 1.2;
    }
    .font-05 {
      font-size: 0.5rem;
      line-height: 1.2;
    }
    .font-025 {
      font-size: 0.25rem;
      line-height: 1.2;
    }
    .margin-zero {
      margin: 0 !important;
    }
    .react-datepicker-wrapper {
      width: 100%;
    }
  }
}
