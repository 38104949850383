/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url(./assets/fonts/open-sans/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans Light';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'),
    url(./assets/fonts/open-sans/OpenSans-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans Semi-Bold';
  font-style: normal;
  font-weight: 500;
  src: local('Open Sans Semi-Bold'), local('OpenSans-SemiBold'),
    url(./assets/fonts/open-sans/OpenSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans Bold';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url(./assets/fonts/open-sans/OpenSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: normal;
  src: local('IBM Plex Sans Regular'), local('IBMPlexSans-Regular'),
    url(./assets/fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'IBM Plex Sans Light';
  font-style: normal;
  font-weight: 300;
  src: local('IBM Plex Sans Light'), local('IBMPlexSans-Light'),
    url(./assets/fonts/IBM_Plex_Sans/IBMPlexSans-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'IBM Plex Sans Semi-Bold';
  font-style: normal;
  font-weight: 500;
  src: local('IBM Plex Sans Semi-Bold'), local('IBMPlexSans-SemiBold'),
    url(./assets/fonts/IBM_Plex_Sans/IBMPlexSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'IBM Plex Sans Bold';
  font-style: normal;
  font-weight: 600;
  src: local('IBM Plex Sans Bold'), local('IBMPlexSans-Bold'),
    url(./assets/fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf) format('truetype');
}
