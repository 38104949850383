.btn {
  // position: relative;
  // overflow: hidden;
  // margin: 4px 1px;
  // border-radius: $border-radius;
  cursor: pointer;

  &:hover,
  &:focus {
    @include opacity(1);
    outline: 0 !important;
  }
  &:active,
  &.active {
    @include box-shadow(none);
    outline: 0 !important;
  }
  &.rounded-circle {
    width: 30px;
    height: 29px;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin-right: 12px;
  }

  &.pointer-events-none {
    pointer-events: none;
    opacity: 0.3;
  }
  &.btn-xs {
    padding: 0.2rem !important;
    font-size: 0.65rem !important;
  }
}
.half-rounded-circle {
  border-radius: 25% !important;
}

.btn {
  @include box-shadow(none);
}

.btn {
  &:disabled,
  &[disabled],
  &.disabled {
    cursor: default;
    &.btn-primary {
      background-color: #b8bac6;
      border-color: #b8bac6;
      // @include opacity(1);
    }
    &.btn-outline-primary {
      background-color: unset;
      border-color: #b8bac6;
      color: #b8bac6;
    }
  }
}

.btn-none {
  background-color: unset !important;
  padding: 0 !important;
}

// .btn-lg {
//   @include btn-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-base, $border-radius-lg);
// }
// .btn-sm {
//   @include btn-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-base, $border-radius-sm);
// }

// .btn-wd {
//   min-width: 140px;
// }
