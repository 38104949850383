.response-status {
  .card {
    .card-body {
      .card-title {
        font-size: $h1-font-size;
        font-family: $font-light;
      }
      .card-subtitle {
        font-size: $h3-font-size;
      }
    }
  }
}
