.menu-cliente {
  position: absolute;
  top: 0;
  z-index: 1;
  .popover {
    .list-group-item {
      border: 0;
      padding: $table-cell-padding-sm;
      width: calc(100% - 2px);
      &:hover {
        text-decoration: $link-hover-decoration;
      }
      &.selected {
        color: initial;
        font-family: $font-bold;
      }
    }
    .popover-inner {
      .rodape {
        margin: 0 0 $border-radius 0;
        padding-top: $popover-header-padding-x;
        border: 0;
        border-top: $border-width solid $border-color;
        line-height: 20px;
        border-radius: 0;
        text-align: center;
      }
      .popover-body {
        padding: $popover-header-padding-y 0;
        max-height: $popover-max-width;
        width: $popover-max-width;
        .icon {
          width: 17px;
          margin-right: 5px;
          &.none {
            margin-right: 22px;
          }
        }
      }
      .popover-header {
        background-color: $popover-bg;

        .list-group-flush {
          border-top: 1px;
        }
      }
    }
  }
}

.font-size9 {
  font-size: 9px;
}

.font-size10 {
  font-size: 10px;
}
