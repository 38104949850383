@import 'wizard';

.borderos,
.wizard-bordero {
  .selecao-itens {
    font-size: 14px;
    .selecao-itens-numero {
      width: 40px;
      height: 28px;
      border: solid 1px #25b7f7;
      background-color: #e9f6fc;
      font-family: $font-bold;
      font-size: 15px;
      color: #25b7f7;
      text-align: center;
      line-height: 26px;
    }
  }
  .step-wizard-nav {
    justify-content: center;
    display: flex;
    .nav {
      width: 90%; // Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
      padding-top: 15px;
    }
  }
  .card {
    margin-bottom: 0; // Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
    min-height: calc(100vh - 108px);
    .card-body {
      &.home--scrollable-content {
        max-height: calc(100vh - 190px);
        overflow-x: auto;
      }
      .table {
        thead {
          th {
            border-width: 1px;
            background-color: #f6f6f6;
            font-size: 12px;
            color: #4a4a4a;
            font-family: $font-semi-bold;
          }
        }

        th,
        td {
          border-color: #e5e5e5;
          vertical-align: middle;
        }

        td {
          &.bordero-status {
            font-weight: $font-weight-bold;
            font-size: $font-size-base;
            &.selecao {
              color: #25b7f7;
            }
            &.analise {
              color: #f1bc57;
            }
          }
          .btn {
            &.rounded-circle {
              width: 30px;
              height: 29px;
              padding: 0;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              font-size: 14px;
              margin-right: 12px;
            }

            &.pointer-events-none {
              pointer-events: none;
              opacity: 0.3;
            }
          }
          &.expand-td-icon,
          &.expand-td-icon-disabled {
            font-size: 18px;
            font-weight: bold;
          }
          &.expand-td-icon {
            cursor: pointer;
            color: #25b7f7;
          }
          &.expand-td-icon-disabled {
            color: #ccc;
          }
        }
        tr {
          &.row-expandable {
            td {
              padding: 0;
            }
          }
        }
      }
      .footer {
        padding-bottom: $navbar-padding-x;
      }
      .infoBordero {
        // padding: 8px;
        padding: 25px $custom-control-spacer-x 0 $custom-control-spacer-x;
        display: flex;
        flex-direction: column;
        max-height: calc(100% - 50px);
        overflow-y: auto;
        position: relative;
      }
    }
  }
  .alerta-conta-corrente {
    margin: -15px 0 15px 0;
  }
}

.form-control-sm {
  height: calc(1em + 0.75rem + 2px);
  padding: 0.3rem 0.75rem;
  font-size: 0.7rem;
}

.th-xs {
  width: 50px;
}

.th-sm {
  width: 100px;
}

.th-md {
  width: 150px;
}

.th-lg {
  width: 200px;
}

.btn-show-menu-right {
  margin-left: 30px;
  margin-right: 10px; // Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
  padding: 0;
  line-height: 1;
  display: block !important;
  min-width: 65px !important;
  border-color: transparent;
  i {
    display: block;
    font-size: 30px;
    line-height: 0;
    height: 20px;
    &::before {
      line-height: 0.75;
    }
  }
  span {
    font-size: 9px;
  }
}

.react-bootstrap-table {
  th {
    .order {
      .caret {
        color: #999 !important;
      }
      .dropup {
        .caret::before {
          padding: 2px;
          font-family: 'fa';
          content: '\eb21';
        }
      }
      .dropdown {
        .caret::before {
          padding: 2px;
          font-family: 'fa';
          content: '';
        }
      }
    }

    .react-bootstrap-table-sort-order {
      .caret {
        color: #777 !important;
      }
      .caret::before {
        padding: 2px;
        font-family: 'fa';
        content: '\eb1d';
      }
      &.dropup {
        .caret::before {
          padding: 2px;
          font-family: 'fa';
          content: '\eb20';
        }
      }
    }
  }
}

.borderos {
  .listagem-bordero {
    .react-bootstrap-table {
      max-height: calc(100vh - 210px);
      overflow: auto;
    }
  }
  .col-data-inicio {
    padding-right: 6px;
  }
  .col-data-final {
    padding-left: 6px;
  }
  .content-bordero {
    transition: all 0.2s linear;
    // max-width: calc(100% - 270px); Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
    padding: 0 5px !important;
  }
  // Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
  // &.collapsed {
  //   .content-bordero {
  //     max-width: 100%;
  //   }
  // }
  .fixed-side-info {
    @include transitions(300ms, ease);
    display: block;
    // Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
    // -ms-flex: 0 0 270px;
    // flex: 0 0 270px;
    // width: 270px;
  }
  .fixed-side {
    @include transitions(300ms, ease);
    animation: slideInRight 0.5s;
    top: 0;
    right: 0;
    max-width: 270px;
    height: 100%;
    border-left: solid 1px #e5e5e5;
    position: fixed;
    background-color: #f6f6f6;
    // padding: 0 $custom-control-spacer-x 0 $custom-control-spacer-x;
    width: 100%;
    display: flex;
    flex-direction: column;
    // overflow: hidden;

    .header {
      padding: 20px 14px 24px;
      border-bottom: 1px solid #e5e5e5;
      height: 78px;
      .title {
        color: $gray-900;
        font-family: $font-light;
        font-size: $h1-font-size;
        margin-bottom: 0;
        margin-top: 5px;
      }
    }

    .body {
      padding: 14px;
      display: flex;
      flex-direction: column;
      max-height: calc(100% - 138px);
      // min-height: 70%;
      overflow-y: auto;
      position: relative;
    }
    .bottom {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      padding: 14px;
      border-top: solid 1px #e5e5e5;
      background-color: #f6f6f6;
    }
  }

  .card {
    .card-body {
      overflow-x: hidden;
      //padding: 0.4px 0.25rem 0 0.5rem;
      padding: 0.4px 0.8rem 0 1rem;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
    .title,
    .card-title {
      font-size: $h1-font-size;
      color: $gray-900;
      font-family: $font-light;
      margin-bottom: 38px;
    }
    .card-header {
      padding: 1.3rem 1.25rem;
      border-color: transparent;
    }
  }
}

@include media-breakpoint-down(xl) {
  .borderos {
    .card {
      min-height: calc(100vh - 105px);
    }
  }
}

.modal-lg2 {
  max-width: 80%;
}

.cursor-pointer {
  cursor: pointer;
}

div:focus {
  outline: -webkit-focus-ring-color auto 0 !important;
}

.no-pad {
  padding: 0;
}

.no-border {
  border: 0;
}

.no-outline {
  outline: 0 !important;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.background-transparent {
  background-color: transparent;
}
