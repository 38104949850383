.container {
  @include media-breakpoint-down(md) {
    padding-right: $grid-gutter-width-sm / 2;
    padding-left: $grid-gutter-width-sm / 2;
  }
  @include media-breakpoint-between(lg, xxl) {
    padding-right: $grid-gutter-width / 2;
    padding-left: $grid-gutter-width / 2;
  }
  @include media-breakpoint-up(xxl) {
    padding-right: $grid-gutter-width-lg / 2;
    padding-left: $grid-gutter-width-lg / 2;
  }
}

.row {
  @include media-breakpoint-down(md) {
    $gutter: 14px;
    margin-right: -$grid-gutter-width-sm / 2;
    margin-left: -$grid-gutter-width-sm / 2;
    > .col,
    > [class*='col-'] {
      padding-right: $grid-gutter-width-sm / 2;
      padding-left: $grid-gutter-width-sm / 2;
    }
  }
  @include media-breakpoint-between(lg, xxl) {
    $gutter: 16px;
    margin-right: -$grid-gutter-width / 2;
    margin-left: -$grid-gutter-width / 2;
    > .col,
    > [class*='col-'] {
      padding-right: $grid-gutter-width / 2;
      padding-left: $grid-gutter-width / 2;
    }
  }
  @include media-breakpoint-up(xxl) {
    $gutter: 24px;
    margin-right: -$grid-gutter-width-lg / 2;
    margin-left: -$grid-gutter-width-lg / 2;
    > .col,
    > [class*='col-'] {
      padding-right: $grid-gutter-width-lg / 2;
      padding-left: $grid-gutter-width-lg / 2;
    }
  }
}
