.popover--titulos-information {
  .popover {
    .popover-inner {
      .popover-header {
        padding: $popover-header-padding-y 20px;
      }
      .popover-body {
        padding: 0 2px;
        max-height: $popover-max-width;
        overflow-y: auto;
      }
    }
  }
}
