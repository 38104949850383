label {
  font-family: $font-semi-bold;
  color: #242b3b;
  font-size: $font-size-base;

  &.required-label {
    &:after {
      content: ' *';
    }
  }
}

.info {
  .form-group {
    margin-bottom: 1.5rem;
  }
  label {
    font-size: $font-size-base;
  }
  .total-price {
    font-family: $font-bold;
    font-size: 22px;
    color: $primary;
  }
}

.form-control,
.form-check-label {
  &:invalid,
  &.is-invalid {
    padding-right: 0.75rem;
    background-image: unset;
  }
}

input[type='search'] {
  font-family: $font-regular, fa;

  &::placeholder {
    color: #b8bac6;
  }
}

// RADIO Custom

/* The radio */
.radio {
  display: block;
  position: relative;
  padding-left: 15px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
}

div.form-check {
  padding-left: 0;
}

fieldset.form-group {
  margin-bottom: 40px;
}

/* Hide the browser's default radio button */
.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkround {
  position: absolute;
  top: 4px;
  left: 0;
  height: 10px;
  width: 10px;
  background-color: #fff;
  border-color: $blue;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
.radio input:checked ~ .checkround {
  background-color: #fff;
}

input.form-check-input.form-control {
  display: none;
}

.radio input:disabled ~ span.checkround {
  border-color: #dadada;
  background-color: #dddddd;
  cursor: not-allowed;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkround:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio input:checked ~ .checkround:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio .checkround:after {
  left: 1px;
  top: 1px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: $blue;
}

/* The check */
.check {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  // padding-right: 15px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border-color: $blue;
  border-style: solid;
  border-width: 2px;
}

/* When the checkbox is checked, add a blue background */
.check input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid;
  border-color: $blue;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

form.is-submitting {
  cursor: progress;
}
