.tooltip-wrapper {
  cursor: not-allowed;
}

.tooltip {
  &.bold-font {
    font-family: $font-semi-bold;
  }
  &.top {
    padding: $tooltip-arrow-border-width 0;
  }
  &.right {
    padding: 0 $tooltip-arrow-border-width;
  }
  &.bottom {
    padding: $tooltip-arrow-border-width 0;
  }
  &.left {
    padding: 0 $tooltip-arrow-border-width;
  }
  .max-height {
    max-height: 60px;
    overflow-y: hidden;
  }

  .arrow:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    z-index: -1;
  }
}

.tooltip-inner {
  border: $tooltip-border-width solid $tooltip-border-color;
}

.bs-tooltip-top {
  padding: $tooltip-arrow-height 0;

  .arrow {
    bottom: 2 * $tooltip-border-width;
    &:after {
      bottom: -2 * $tooltip-border-width;
      left: 50%;
      margin-left: -$tooltip-arrow-border-width;
      border-width: $tooltip-arrow-border-width $tooltip-arrow-border-width 0;
      border-top-color: $tooltip-border-color;
    }
  }
}

.bs-tooltip-right {
  .arrow {
    left: 2 * $tooltip-border-width;
    &:after {
      left: -2 * $tooltip-border-width;
      top: 50%;
      margin-top: -$tooltip-arrow-border-width;
      border-width: $tooltip-arrow-border-width $tooltip-arrow-border-width $tooltip-arrow-border-width 0;
      border-right-color: $tooltip-border-color;
    }
  }
}

.bs-tooltip-left {
  .arrow {
    right: 2 * $tooltip-border-width;
    &:after {
      right: -2 * $tooltip-border-width;
      top: 50%;
      margin-top: -$tooltip-arrow-border-width;
      border-width: $tooltip-arrow-border-width 0 $tooltip-arrow-border-width $tooltip-arrow-border-width;
      border-left-color: $tooltip-border-color;
    }
  }
}

.bs-tooltip-bottom {
  padding: $tooltip-arrow-height 0;

  .arrow {
    top: 2 * $tooltip-border-width;
    &:after {
      top: -2 * $tooltip-border-width;
      left: 50%;
      margin-left: -$tooltip-arrow-border-width;
      border-width: 0 $tooltip-arrow-border-width $tooltip-arrow-border-width;
      border-bottom-color: $tooltip-border-color;
    }
  }
}
