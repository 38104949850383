.snackbar {
  visibility: hidden;
  height: 70px;
  background-color: #242b3b;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-weight: $font-weight-normal;
  padding: 10px 40px;
  left: 50%;
  position: absolute;
  z-index: 2000;
  top: 10px;
  font-size: 14px;
  transform: translateX(-50%);
  &.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
    &.error-level {
      background-color: $danger;
    }
    &.success-level {
      background-color: $success;
    }
    &.warning-level {
      background-color: $warning;
    }
  }
}

@-webkit-keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 10px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 10px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    top: 10px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    top: 10px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}
