.authentication {
  height: 100%;
  background-color: $one7-dark-blue;
  // background: linear-gradient(to top, $one7-blue, $one7-dark-blue);

  .logo-wrapper {
    text-align: center;
    display: block;
    justify-content: center;

    img {
      max-width: 100%;
      height: auto;
    }
  }
  .signin {
    width: 435px;

    .info-icons {
      font-size: 140px;
      text-align: center;
    }

    h1 {
      font-family: $font-semi-bold;
      font-size: 1.75rem;
      color: $gray-900;
    }
    p {
      font-size: $font-size-lg;
    }
    label {
      width: 100%;
      font-size: 14px;
    }
    .form-control {
      height: 40px;
    }
    .invalid-feedback {
      font-size: 12px;
      margin-left: 12px;
      margin-top: 8px;
    }
    .button-group {
      width: 100%;
      text-align: center;
      padding-top: 8px;

      .btn {
        padding: $input-padding-y $input-padding-x-lg * 2;
        font-size: 14px;
        letter-spacing: 1px;
        font-weight: bold;
        text-transform: uppercase;
        height: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .forgot-password {
      &[disabled] {
        pointer-events: none;
        color: #ccc;
      }
    }

    .card-body {
      padding: 60px 40px;
    }
  }
  .segundaVia {
    width: 90%;
    height: auto;
    margin-top: 4vh;
    margin-bottom: 3vh;
    .alinhaTexto {
      display: inline-flex;
      align-items: flex-end;
    }
    .font30 {
      font-size: 30px;
      font-weight: bold;
    }
    .visaoSacadoTitulos {
      margin-left: 10px;
      margin-right: 10px;
      overflow: auto;
      @media (min-width: 320px) {
        .col-md-6 {
          flex: 0 0 32%;
          max-width: 32%;
        }
      }
      .react-bootstrap-table {
        height: 42vh;
        overflow: auto;
      }
      .table {
        border: 1px solid lightgray;
      }
    }
    .badge-visaoSacado {
      font-size: 13px;
    }
    .custom-checkbox {
      .custom-control-label {
        cursor: pointer;
      }
    }
    .alignItensCenter {
      align-items: center;
    }
    .marginBotton10 {
      margin-bottom: 10px;
    }
    .marginLeft-15 {
      margin-left: -15px;
    }

    ::-webkit-scrollbar-track {
      background: #dacccc;
    }
    ::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }
    ::-webkit-scrollbar-thumb {
      background: #25b7f79e;
      border-radius: 5px;
    }

    .responsividadeCaptcha {
      transform: scale(0.77) !important;
      -webkit-transform: scale(0.77) !important;
      transform-origin: 0 0 !important;
      -webkit-transform-origin: 0 0 !important;
    }
    .tamanhoSearch {
      margin-right: -22px;
      margin-left: -8px;
    }

    .selecao-itens {
      margin-bottom: -52px;
      .half-rounded-circle {
        width: 40px;
        height: 28px;
        border: solid 1px #25b7f7;
        background-color: #e9f6fc;
        font-family: 'Open Sans Bold';
        font-size: 15px;
        color: #25b7f7;
        text-align: center;
        line-height: 26px;
      }
    }

    @media (max-width: 425px) {
      .selecao-itens {
        margin-bottom: 0px;
      }

      .col-md-6 {
        flex: 0 0 100%;
        max-width: 100% !important;
      }

      .col-md-9 {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .btnVsCuston {
        width: auto;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-top: -90px;
        float: right;
        width: min-content;
      }
    }

    .esconder {
      display: none;
    }
  }
}

.heightAuto {
  height: auto !important;
  min-height: 100%;
  align-items: start !important;
}

.visaoSacadoBtn {
  margin-top: 25px;
  margin-bottom: -25px;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  text-decoration: none !important;
  color: #25b7f7;
  border-color: #25b7f7;
  width: 50%;
}

.visaoSacadoBtnNew {
  margin-top: 25px;
  margin-bottom: -25px;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  text-decoration: none !important;
  color: #000000;
  width: 100%;
}

.img-logo-boleto {
  margin-right: 10px;
}

.visaoSacadoBtn:hover {
  color: #fff;
  background-color: #25b7f7;
  border-color: #25b7f7;
}

.visaoSacadoBtnNew:hover {
  color: #fff;
  background-color: #1a284d;
  .img-logo-boleto {
    svg {
      path {
        color: white;
        stroke: white;
      }
    }
  }
}

.btnVsCuston {
  width: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  float: right;
}

.msgBoletoIndisponivel {
  .swal-text {
    font-size: 0.85rem !important;
  }
}

.btnBoletoSacado {
  width: 108px !important;
}
