.status-success {
  color: $success;
}

.status-fail {
  color: $danger;
}

.status-pending {
  color: $gray-500;
}

.progress {
  height: 7px;
}

.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 59px 0px;
  border-radius: 4px;
  border: solid 1px #b8bac6;
  margin-bottom: 32px;
  opacity: 0.9;

  &.disabled {
    opacity: 0.38;
    pointer-events: none;
  }

  &:hover,
  &:active {
    border: solid 1px $blue;
    opacity: 1;
  }

  .file-icon {
    font-size: 24px;
    margin-right: 10px;
  }

  .info-icon {
    font-size: 20px;
    color: $blue;
  }

  .link {
    color: $blue;
  }

  h4 {
    font-weight: $font-weight-bold;
  }
}

div {
  .file-wrapper {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 10px;
    min-height: 30px;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    .font-bold {
      font-weight: bold;
    }
    .file-obs {
      padding-right: 20px;
      padding-left: 20px;
    }
    .row {
      margin-right: -5px !important;
      margin-left: -5px !important;
    }
  }
}

.btn {
  vertical-align: unset;
  &.btn-minimal {
    font-weight: $font-weight-bold;
    .icon-times {
      &:hover {
        opacity: 1;
      }
      opacity: 0.5;
      font-size: 18px;
      color: $gray-700;
      &.color-danger {
        color: $danger !important;
      }
    }
    min-width: 0 !important;
    height: 0 !important;
  }
}
