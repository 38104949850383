.relatorio {
  .card {
    .card-body {
      overflow-x: hidden;
      padding-bottom: 0;
      transform: translateZ(0);
      height: calc(100vh - 106px);

      .body {
        height: calc(100vh - 184px);
        .nomeCliente {
          display: none;
        }

        .selecao-itens {
          font-size: 14px;
          .selecao-itens-numero {
            width: 40px;
            height: 28px;
            border: solid 1px #25b7f7;
            background-color: #e9f6fc;
            font-family: $font-bold;
            font-size: 15px;
            color: #25b7f7;
            text-align: center;
            line-height: 26px;
          }
        }
        .table-responsive {
          &.borderos {
            max-height: calc(100vh - 336px);
            overflow-x: auto;
            .react-bootstrap-table {
              table {
                th {
                  white-space: nowrap;
                }
              }
            }
          }
          &.titulos {
            // max-height: calc(100vh - 376px);
            max-height: calc(100vh - 300px);
            overflow: auto;
            .react-bootstrap-table {
              table {
                table-layout: auto;
                th {
                  white-space: nowrap;
                }
              }
            }
          }
          &.concentracao-sacados {
            max-height: calc(100vh - 284px);
            overflow-x: auto;
            .react-bootstrap-table {
              table {
                table-layout: auto;
                th {
                  white-space: nowrap;
                }
                th:nth-child(1) {
                  min-width: 200px;
                }
              }
            }
          }
          &.debitos {
            max-height: calc(100vh - 284px);
            overflow: auto;
            .react-bootstrap-table {
              table {
                th {
                  white-space: nowrap;
                }
              }
            }
          }
        }

        .badge-relatorio {
          padding: 8px;
          font-size: $font-size-base;
          background-color: $gray-100;
          border: 1px solid $gray-300;
          color: $gray-600;
          font-weight: $font-weight-bold;

          &:not(.ml-auto) {
            margin-left: 15px;
          }
        }
      }

      .title {
        color: $gray-900;
        font-family: $font-light;
        font-size: $h1-font-size;
        margin-bottom: 0;
        margin-top: 5px;
      }
      .subTitle {
        font-size: 12px;
        margin-top: 12px;
      }
      .footer {
        margin-top: auto;
        display: flex;

        .svg-icon {
          margin-right: 15px;
          margin-top: -3px;

          &.right-side {
            margin-right: 0;
            margin-left: 12px;
            font-size: 14px;
            margin-top: -12px;
          }
          svg,
          &.far {
            height: 12px;
            fill: currentColor;
            width: unset;
          }
        }
        .btn {
          min-width: 113px;
          height: 35px;
          display: inline-flex;
          padding: 0;
          align-items: center;
          justify-content: center;
        }
      }

      .content-relatorio {
        // max-width: calc(100% - 269px); Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
        transition: all 0.2s linear;
        &.collapsed {
          max-width: 100%;
        }
      }

      .fixed-side-info {
        @include transitions(300ms, ease);
        display: block;
        // Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
        // -ms-flex: 0 0 269px;
        // flex: 0 0 269px;
        // width: 269px;
      }

      .fixed-side {
        @include transitions(300ms, ease);
        animation: slideInRight 0.5s;
        top: 0;
        right: 0;
        // max-width: 270px; Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente
        height: 100%;
        border-left: solid 1px #e5e5e5;
        position: fixed;
        background-color: #f6f6f6;
        // padding: 0 $custom-control-spacer-x 0 $custom-control-spacer-x;
        width: 100%;
        display: flex;
        flex-direction: column;
        // overflow: hidden;

        .header {
          padding: 20px 14px 24px;
          border-bottom: 1px solid #e5e5e5;
        }

        .body {
          padding: 14px;
          display: flex;
          flex-direction: column;
          max-height: calc(100% - 150px);
          // min-height: 70%;
          overflow-y: auto;
          position: relative;
        }
        .bottom {
          position: absolute;
          bottom: 0;
          width: 100%;
          left: 0;
          padding: 14px;
          border-top: solid 1px #e5e5e5;
          background-color: #f6f6f6;
        }
      }
    }
  }
}

.react-bootstrap-table {
  th {
    .order {
      .caret {
        color: #999 !important;
      }
      .dropup {
        .caret::before {
          padding: 2px;
          font-family: 'fa';
          content: '\eb21';
        }
      }
      .dropdown {
        .caret::before {
          padding: 2px;
          font-family: 'fa';
          content: '';
        }
      }
    }

    .react-bootstrap-table-sort-order {
      .caret {
        color: #777 !important;
      }
      .caret::before {
        padding: 2px;
        font-family: 'fa';
        content: '\eb1d';
      }
      &.dropup {
        .caret::before {
          padding: 2px;
          font-family: 'fa';
          content: '\eb20';
        }
      }
    }
  }
}

.flex-1 {
  flex: 1;
}

.custom-link:hover {
  color: $link-hover-color;
  text-decoration: none;
}

.sup {
  margin-left: 2px;
  vertical-align: middle;
  color: $gray-700;
  font-size: 12px;
}

@media print {
  @page {
    size: legal landscape;
  }
  body {
    background-color: #fff !important;
    * {
      max-height: max-content !important;
      max-width: max-content !important;
      visibility: hidden;
      padding-right: 0px !important;
    }
  }
  .export-buttons,
  .export-buttons *,
  .boletos-lote,
  .boletos-lote * {
    visibility: hidden !important;
  }

  .card-body,
  .content,
  .container {
    height: max-content !important;
    padding: 1px !important;
    padding-right: 0px !important;
    margin-right: -25px !important;
  }

  .subTitle,
  nav {
    display: none !important;
  }

  .content-relatorio {
    max-height: max-content !important;
    max-width: max-content !important;
    padding-right: 0px !important;
    margin-right: -25px !important;
    width: 100%; // Douglas - 27/01/2020 - Ajustes para Responsividade Visao Cliente

    .nomeCliente {
      display: block !important;
      max-width: 95% !important;
      .logoOne7 {
        margin-top: -60px;
      }
    }

    .selecao-itens {
      visibility: hidden !important;
      display: none !important;
    }

    .body {
      height: max-content !important;
      background-color: #fff !important;
      * {
        max-height: max-content !important;
        visibility: visible;
      }
      .col-titulo-relatorio {
        flex: 0 0 100% !important;
      }
      .badge-relatorio {
        padding: 8px !important;
      }
    }

    .footer * {
      visibility: hidden;
    }

    .table-responsive {
      max-height: max-content !important;
      overflow: hidden !important;
      * {
        max-height: max-content !important;
      }

      table {
        th {
          padding: 0.2rem !important;
          background-color: #f5f5f5 !important;
          color: #000;
          span {
            visibility: hidden !important;
          }
        }
        td {
          padding: 0.2rem !important;
        }
      }

      /* ajuste de colunas especificos de cada relatorio */
      &.titulos {
        * {
          font-size: 11px;
        }
        table {
          table-layout: fixed !important;
          color: #000 !important;
          border: 1px solid #ccc;
          th {
            border: 1px solid #ccc;
          }
          td {
            border: 1px solid #ccc;
          }
          th:nth-child(2) {
            width: 100px !important;
          }
          th:nth-child(3) {
            width: 80px !important;
          }
          th:nth-child(4) {
            width: 80px !important;
          }
          th:nth-child(5) {
            width: 60px !important;
          }
          th:nth-child(6) {
            width: 50px !important;
          }
          th:nth-child(7) {
            width: 50px !important;
          }
          th:nth-child(8) {
            width: 35px !important;
          }
          th:nth-child(9) {
            width: 80px !important;
          }
          th:nth-child(10) {
            width: 80px !important;
          }
          th:nth-child(11) {
            width: 55px !important;
          }
          th:nth-child(12):not(.tamanhoCorreto) {
            width: 130px !important;
          }
          th:nth-child(13) {
            width: 90px !important;
          }
          th:nth-child(14) {
            width: 90px !important;
          }
          th:nth-child(15) {
            width: 90px !important;
          }
          th:nth-child(16) {
            width: 80px !important;
          }
          th:nth-child(17) {
            width: 80px !important;
          }
          th:nth-child(18) {
            width: 80px !important;
          }
          th:first-child,
          th:last-child {
            display: none !important;
            * {
              display: none !important;
            }
          }
          td:first-child,
          td:last-child {
            display: none !important;
            * {
              display: none !important;
            }
          }
        }
      }

      &.borderos {
        * {
          font-size: 11px;
        }
        table {
          table-layout: fixed !important;
          color: #000 !important;
          border: 1px solid #ccc;
          max-width: 94% !important;
          th {
            border: 1px solid #ccc;
          }
          td {
            border: 1px solid #ccc;
          }
        }

        &.borderos-cliente {
          table {
            th {
              width: 170px !important;
            }
            th:nth-child(3) {
              width: 290px !important;
            }
          }
        }

        &.borderos-geral {
          table {
            th:nth-child(1) {
              width: 100px !important;
            }
            th:nth-child(2) {
              width: 100px !important;
            }
            th:nth-child(3) {
              width: 100px !important;
            }
            th:nth-child(4) {
              width: 100px !important;
            }
            th:nth-child(6) {
              width: 100px !important;
            }
            th:nth-child(7) {
              width: 100px !important;
            }
            th:nth-child(8) {
              width: 100px !important;
            }
            th:nth-child(9) {
              width: 110px !important;
            }
            th:nth-child(10) {
              width: 100px !important;
            }
            th:nth-child(11) {
              width: 100px !important;
            }
            th:nth-child(12) {
              width: 100px !important;
            }
          }
        }
      }

      &.concentracao-sacados {
        * {
          font-size: 12px;
        }
        table {
          table-layout: fixed !important;
          color: #000 !important;
          border: 1px solid #ccc;
          max-width: 94% !important;
          th {
            width: 160px !important;
            border: 1px solid #ccc;
          }
          td {
            border: 1px solid #ccc;
          }
          th:first-child {
            width: 650px !important;
          }
        }
      }

      &.debitos {
        * {
          font-size: 11px;
        }
        table {
          table-layout: fixed !important;
          color: #000 !important;
          border: 1px solid #ccc;
          max-width: 94% !important;
          th {
            width: 100px !important;
            border: 1px solid #ccc;
          }
          td {
            border: 1px solid #ccc;
          }
          th:nth-child(2) {
            width: 200px !important;
          }
        }
      }
    }
  }
}
