$datepicker__background-color: $light !default;
$datepicker__border-color: $card-border-color !default;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: $gray-400 !default;
$datepicker__selected-color: $primary !default;
$datepicker__text-color: $input-color !default;
$datepicker__header-color: $input-color !default;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%) !default;

$datepicker__border-radius: $border-radius-lg !default;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: $font-size-base !default;
$datepicker__font-family: $font-regular !default;
$datepicker__item-size: 1.7rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-size: 0.45rem !default;
$datepicker__triangle-size: 8px !default;

@import '~react-datepicker/src/stylesheets/datepicker-cssmodules.scss';
